/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import axios from "axios";
import $ from "jquery";
import swal from "sweetalert";
import Slider from "react-slick";
import {
  appId,
  apiUrlCtrng,
  cateringId,
  productNoImg,
} from "../Helpers/Config";
import close from "../../common/images/cross-close.png";
import {
  stripslashes,
  getReferenceID,
  showPriceValue,
  showPriceValueNew,
  showLoader,
  hideLoader,
} from "../Helpers/SettingHelper";
var Parser = require("html-react-parser");
var qs = require("qs");
const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  dots: true,
  arrows: false,
  adaptiveHeight: true,
};

const settingsPorduct = {
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class Product extends Component {
  constructor(props) {
    super(props);
    var cartID =
      this.props.match.params.cartID !== "" &&
      typeof this.props.match.params.cartID !== undefined &&
      typeof this.props.match.params.cartID !== "undefined"
        ? this.props.match.params.cartID
        : "";
    this.state = {
      selectedCategory: this.props.match.params.catSlug,
      selectedSubCategory: this.props.match.params.subCatSlug,
      cartID: cartID,
      productState: [],
      allproductData: [],
      productData: [],
      editproductData: [],
      prCommon: "",
      product_loading: "",
      selectedPro: 0,
      selectedProduct: "",
      productGrandTotal: 0,
      selectpaxcount: 0,
      settupType: "",
      specialInstruction: "",
      addonSelection: [],
      equipmentSetup: [],
      settupSelection: [],
      menuSet: [],
      additionalPrice: 0,
      modifierPrice: 0,
      individualmodifierPrice: 0,
      cindex: "",
      pindex: "",
      setMultimodifiers: [],
      cartdetails: [],
      productStart: 0,
      cartEditStart: cartID !== "" ? 0 : 1,
      minmax_validation: "",
    };
  }
  componentDidUpdate() {
    if (this.state.productStart === 0) {
      if ($(".product-lhs-inner").length > 0) {
        showLoader("product-lhs-inner", "class");
      }
      showLoader("product-lhs-inner", "class");
      this.setState({ productData: [], productStart: 1 });
    }
  }
  componentDidMount() {
    $(window).scroll(function () {
      if ($(".product_acc_grp").length > 0) {
        var distance = $(".product_acc_grp").offset().top,
          $window = $(window);
        if ($(window).scrollTop() >= distance - 130) {
          $(".pdr_right").addClass("price-fixed");
          var containerWidth = $(".catering-product-list").outerWidth();
          var elementWidth = $(".price-fixed").outerWidth();
          var containerOffsetLeft = $(".catering-product-list").offset().left;
          var containerOffsetRight =
            parseFloat(containerOffsetLeft) + parseFloat(containerWidth) - 360;
          $(".price-fixed").css("left", containerOffsetRight);
        } else {
          $(".pdr_right").removeClass("price-fixed");
        }
      }
    });
    $(document).ready(function () {
      $("body").on("click", ".accordion_title", function () {
        var accroot = $(this).parents(".accordion");
        if (accroot.find(".accordion_title.active").length > 0) {
          accroot.find(".accordion_body").slideUp();
          accroot.find(".accordion_title").removeClass("active");
        } else {
          accroot.find(".accordion_body").slideDown();
          accroot.find(".accordion_title").addClass("active");
        }
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.allproductData !== nextProps.productData) {
      if (
        nextProps.productData !== null &&
        Object.keys(nextProps.productData).length > 0
      ) {
        this.setState(
          {
            allproductData: nextProps.productData,
            productData: nextProps.productData[0].result_set,
            prCommon: nextProps.productData[0].common,
            selectedProduct:
              nextProps.productData[0].result_set[0].product_slug,
            selectpaxcount:
              nextProps.productData[0].result_set[0].product_min_pax,
          },
          function () {
            setTimeout(() => {
              hideLoader("product-lhs-inner", "class");
            }, 500);
            this.setMultimodifiers();
          }
        );
      }
    }
    if (
      this.state.editproductData !== nextProps.productData &&
      this.state.cartdetails !== nextProps.productState.cartItems &&
      this.state.cartID !== "" &&
      typeof this.state.cartID !== undefined &&
      typeof this.state.cartID !== "undefined"
    ) {
      if (
        nextProps.productData !== null &&
        Object.keys(nextProps.productData).length > 0 &&
        nextProps.productState.cartItems.length > 0
      ) {
        this.setState(
          {
            editproductData: nextProps.productData,
            cartdetails: nextProps.productState.cartItems,
            cartEditStart: 1,
          },
          function () {
            this.setCartData();
            this.setModifierCount();
          }
        );
      }
    }
    if (this.state.product_loading !== nextProps.productState.product_loading) {
      this.setState({
        product_loading: nextProps.productState.product_loading,
      });
    }
    if (this.state.productState !== nextProps.productState) {
      this.setState({
        productState: nextProps.productState,
      });
    }

    if (this.props.uploadDataImage !== nextProps.uploadDataImage) {
      if (nextProps.uploadDataImage.length > 0) {
        var logoSection = $(
          "#" + this.state.cindex + "s #pro-" + this.state.pindex
        );
        if (
          nextProps.uploadDataImage &&
          nextProps.uploadDataImage[0].status === "success"
        ) {
          logoSection
            .find(".imagename")
            .text(nextProps.uploadDataImage[0].file.file_name);
          logoSection.find(".delete_image").show();
          swal("Success!", "Succesfully uploaded!", "success");
        } else {
          logoSection.find(".imagename").text("");
          logoSection.find(".delete_image").hide();
          swal("Error!", "Try Again!", "error");
        }
        this.setState({ cindex: "", pindex: "" });
      }
    }
    if (this.props.deleteDataImage !== nextProps.deleteDataImage) {
      if (nextProps.deleteDataImage.length > 0) {
        var logoSection = $(
          "#" + this.state.cindex + "s #pro-" + this.state.pindex
        );
        if (
          nextProps.deleteDataImage &&
          nextProps.deleteDataImage[0].status === "success"
        ) {
          logoSection.find(".imagename").text("");
          logoSection.find(".delete_image").hide();
          swal("Success!", "Succesfully deleted!", "success");
        }
      }
    }
  }
  /* Default Set Multiple Modifier Array */
  setMultimodifiers() {
    var setMultimodifiers = [];
    if (this.state.productData.length > 0) {
      this.state.productData.map((item, index) => {
        item.set_menu_component.map((item1, index1) => {
          item1.product_details.map((item2, index2) => {
            if (
              item2.product_quantity_inc === "1" &&
              item2.modifiers.length > 0
            ) {
              if (
                setMultimodifiers[index] === "" ||
                typeof setMultimodifiers[index] === undefined ||
                typeof setMultimodifiers[index] === "undefined"
              ) {
                setMultimodifiers[index] = [];
              }
              if (
                setMultimodifiers[index][index1] === "" ||
                typeof setMultimodifiers[index][index1] !== undefined ||
                typeof setMultimodifiers[index][index1] === "undefined"
              ) {
                setMultimodifiers[index][index1] = [];
              }
              if (
                setMultimodifiers[index][index1][index2] === "" ||
                typeof setMultimodifiers[index][index1][index2] === undefined ||
                typeof setMultimodifiers[index][index1][index2] === "undefined"
              ) {
                setMultimodifiers[index][index1][index2] = [0];
              }
            }
          });
        });
      });
    }
    this.setState({ setMultimodifiers: setMultimodifiers });
  }
  /* For Edit Cart Set Settup List */
  setCartData() {
    var editcartId = this.state.cartID;
    var cartItem = this.state.cartdetails[editcartId];
    var cartPro = this.state.productData[0];
    var equipmentSetup = [];
    if (cartItem.equipment.length > 0 && cartPro.equipment.length > 0) {
      cartPro.equipment.map((eqp, index) => {
        cartItem.equipment.map((ceqp) => {
          if (eqp.product_catering_equipment_id == ceqp.cart_equipment_id) {
            eqp["qty"] = ceqp.cart_equipment_qty;
            equipmentSetup[index] = eqp;
          }
        });
      });
    }
    var selectedSetup = [];
    var settupType = "";
    if (cartItem.setup.length > 0) {
      var selectedSettup = cartItem.setup[0].cart_setup_type;
      var settup = [];
      if (selectedSettup === "Delivery") {
        settup = cartPro.delivery;
        settupType = "delivery";
      } else if (selectedSettup === "Buffet") {
        settup = cartPro.buffet;
        settupType = "buffet";
      }
      if (selectedSettup === "Self Collection") {
        settup = cartPro.self_collection;
        settupType = "buffet";
      }
      if (settup.length > 0) {
        settup.map((setup, index) => {
          cartItem.setup.map((csetup) => {
            var productSetupID = setup.catering_delivery_setup_id;
            if (settupType === "delivery") {
              productSetupID = setup.catering_delivery_setup_id;
              // cookie.save("self_collection_selected", "0");
            } else if (settupType === "buffet") {
              productSetupID = setup.catering_buffet_setup_id;
            } else if (settupType === "self_collection") {
              productSetupID = setup.catering_self_collection_setup_id;
            }

            if (csetup.cart_setup_id === productSetupID) {
              setup["qty"] = csetup.cart_setup_qty;
              selectedSetup[index] = setup;
            }
          });
        });
      }
    }
    var addonSettupArray = [];
    if (cartItem.addons_setup.length > 0 && cartPro.addons_setup.length > 0) {
      cartPro.addons_setup.map((addon, index) => {
        cartItem.addons_setup.map((addonset) => {
          if (
            addon.product_catering_addons_id === addonset.cart_addon_setup_id
          ) {
            if (
              addonset.addons_setup_values.length > 0 &&
              addon.addons_setup_values.length > 0
            ) {
              addon.addons_setup_values.map((addonval, index1) => {
                addonset.addons_setup_values.map((addonsetval) => {
                  if (
                    addonval.cat_addonvalues_id ===
                    addonsetval.cart_addon_setup_val_id
                  ) {
                    if (
                      addonSettupArray[index] === "" ||
                      typeof addonSettupArray[index] === undefined ||
                      typeof addonSettupArray[index] === "undefined"
                    ) {
                      addonSettupArray[index] = [];
                    }
                    addonval["qty"] = parseInt(
                      addonsetval.cart_addon_setup_val_qty
                    );
                    addonSettupArray[index]["ad-" + index1] = addonval;
                  }
                });
              });
            }
          }
        });
      });
    }
    this.setState(
      {
        equipmentSetup: equipmentSetup,
        settupType: settupType,
        settupSelection: selectedSetup,
        addonSelection: addonSettupArray,
        specialInstruction: cartItem.cart_item_special_notes,
      },
      function () {
        this.calculatedOverAllAmount();
      }
    );
  }
  checkProductSelected(product) {
    var editcartId = 0;
    var cartItem = this.state.cartdetails[editcartId];
    var proSelect = {
      selected: false,
      qty: 0,
      extraqty: 0,
      extraprice: 0,
      custom_logo: "",
      custom_text: "",
      modifiers: [],
    };
    if (
      cartItem !== "" &&
      typeof cartItem !== undefined &&
      typeof cartItem !== "undefined"
    ) {
      if (cartItem.set_menu_component.length > 0) {
        cartItem.set_menu_component.map((item) => {
          if (item.product_details.length > 0) {
            item.product_details.map((pitem) => {
              if (pitem.cart_menu_component_product_id === product.product_id) {
                proSelect.selected = true;
                proSelect.qty = pitem.cart_menu_component_product_qty;
                proSelect.extraqty =
                  pitem.cart_menu_component_product_extra_qty;
                proSelect.extraprice =
                  pitem.cart_menu_component_product_extra_price;
                proSelect.custom_logo = pitem.custom_logo;
                proSelect.custom_text = pitem.custom_text;
                proSelect.modifiers = pitem.modifiers;
              }
            });
          }
        });
      }
    }
    return proSelect;
  }

  checkModifierVal(proModifiers, selecteModifier) {
    var modifierSelect = { selected: false };
    if (proModifiers.length > 0) {
      proModifiers.map((item) => {
        if (item.cart_modifier_id === selecteModifier) {
          modifierSelect.selected = true;
        }
      });
    }
    return modifierSelect;
  }

  setModifierCount() {
    var cartPro1 = this.state.productData[0];
    var setMultimodifiers = this.state.setMultimodifiers;
    var selectedPro = this.state.selectedPro;
    var totalList = 0;

    if (cartPro1.set_menu_component.length > 0) {
      cartPro1.set_menu_component.map((item, cindex) => {
        if (Object.keys(item).length > 0) {
          item.product_details.map((item1, mindex) => {
            if (item1.modifiers.length > 0) {
              var existst = this.checkModifierCount(
                item.menu_component_id,
                item1.product_id
              );

              if (existst > 0) {
                if (
                  setMultimodifiers[selectedPro] !== "" &&
                  typeof setMultimodifiers[selectedPro] !== undefined &&
                  typeof setMultimodifiers[selectedPro] !== "undefined"
                ) {
                  if (
                    setMultimodifiers[selectedPro][cindex] !== "" &&
                    typeof setMultimodifiers[selectedPro][cindex] !==
                      undefined &&
                    typeof setMultimodifiers[selectedPro][cindex] !==
                      "undefined"
                  ) {
                    if (
                      setMultimodifiers[selectedPro][cindex][mindex] !== "" &&
                      typeof setMultimodifiers[selectedPro][cindex][mindex] !==
                        undefined &&
                      typeof setMultimodifiers[selectedPro][cindex][mindex] !==
                        "undefined"
                    ) {
                      var modiArryList = [];

                      totalList = existst;
                      for (let index = 0; index < totalList; index++) {
                        modiArryList.push(index);
                      }
                      setMultimodifiers[selectedPro][cindex][mindex] =
                        modiArryList;
                    }
                  }
                }
              }
            }
          });
        }
      });
    }
    this.setState({ setMultimodifiers: setMultimodifiers });
  }

  checkModifierCount(menu_component_id, product_id) {
    var result = 0;
    var editcartId1 = this.state.cartID;
    var cartItem1 = this.state.cartdetails[editcartId1];
    if (cartItem1.set_menu_component.length > 0) {
      cartItem1.set_menu_component.map((item, index) => {
        if (Object.keys(item).length > 0) {
          item.product_details.map((item1, index1) => {
            if (item1.modifiers.length > 0) {
              if (
                item.menu_component_id === menu_component_id &&
                item1.cart_menu_component_product_id === product_id
              ) {
                result = item1.modifiers.length;
              }
              /*  this.checkModifierCount(item.menu_component_id, item1.product_id);
               */
            }
          });
        }
      });
    }
    return result;
  }

  changeProduct(index, item, product_name) {
    var selProCombo = $("#" + this.state.selectedPro + "s");

    if (product_name !== "") {
      product_name =
        item.product_alias !== ""
          ? stripslashes(item.product_alias)
          : stripslashes(item.product_name);

      $(".more-menu .more-menu-name").html(product_name);
      $(".more-menu-parent").addClass("currect_active");
    } else {
      $(".more-menu .more-menu-name").html("CHECK MORE");
      $(".more-menu-parent").removeClass("currect_active");
    }

    $(".more-menu-parent, .more_categor_info").removeClass("active");
    $(".more_categor_info").hide();

    selProCombo.each(function () {
      $(this)
        .find(".compo_minmax_maindiv")
        .each(function () {
          var selProDiv = $(this);
          selProDiv.removeClass("selected");
          selProDiv.find(".custom_checkbox").show();
          selProDiv.find(".prd_close").hide();
          selProDiv
            .find(".mdfr_list_divlcs")
            .find('input[type="checkbox"]')
            .prop("checked", false);

          selProDiv.find(".increase_qty").hide();
          selProDiv.find(".mdfr_list_divlcs").hide();
          selProDiv.find(".modifier-btn-grp").hide();
          selProDiv.find(".custom_logo_banner").hide();
          selProDiv.find(".popup_price").hide();
        });
    });
    $(".additionals_setuplist")
      .find('input[type="radio"]')
      .prop("checked", false);
    this.setState({
      selectedProduct: item.product_slug,
      selectedPro: index,
      selectpaxcount: item.product_min_pax,
      productGrandTotal: item.product_min_pax * item.product_price,
      specialInstruction: "",
      settupType: "",
      addonSelection: [],
      equipmentSetup: [],
      settupSelection: [],
      menuSet: [],
      additionalPrice: 0,
      modifierPrice: 0,
      individualmodifierPrice: 0,
    });
  }
  /* Update Qyantity in product Package */
  updateProductPax(
    type,
    selectpaxcount,
    minpaxcount,
    maxpaxcount,
    increase_quantity
  ) {
    minpaxcount = parseInt(minpaxcount) > 0 ? parseInt(minpaxcount) : 10;
    increase_quantity =
      parseInt(increase_quantity) > 0 ? parseInt(increase_quantity) : 10;
    selectpaxcount = parseInt(selectpaxcount);

    if (type === "minus") {
      var tempVl =
        selectpaxcount > minpaxcount ? selectpaxcount - increase_quantity : 0;
      if (tempVl >= 1) {
        selectpaxcount = selectpaxcount - increase_quantity;
      }
    } else {
      selectpaxcount = selectpaxcount + increase_quantity;
    }

    if (maxpaxcount > 0 && selectpaxcount > maxpaxcount) {
      return false;
    }

    if (selectpaxcount > 0) {
      this.setState({ selectpaxcount: selectpaxcount }, function () {
        this.calculatedOverAllAmount();
      });
    }
  }

  /* Caluclate Pricing */
  calculatedOverAllAmount() {
    var equipmentPrice = 0;
    var productGrandTotal = 0;
    var settupPrice = 0;
    var addonPrice = 0;

    var stateProDet = this.state.productData[this.state.selectedPro];
    var product_price = stateProDet.product_price;
    var qty = this.state.selectpaxcount;
    var price = product_price * qty;
    var additionalPrice = this.state.additionalPrice * qty;

    let equipmentSetup = this.state.equipmentSetup;
    if (equipmentSetup.length > 0) {
      for (var key2 in equipmentSetup) {
        var equipment = equipmentSetup[key2];
        if (equipment.qty > 0) {
          equipmentPrice =
            parseFloat(equipmentPrice) +
            parseFloat(equipment.catering_equipment_price * equipment.qty);
        }
      }
    }

    let settupSelection = this.state.settupSelection;
    if (Object.keys(settupSelection).length > 0) {
      for (var key in settupSelection) {
        var settup = settupSelection[key];
        var sPrice = 0;
        if (this.state.settupType === "delivery") {
          sPrice = settup.catering_delivery_price;
        } else if (this.state.settupType === "buffet") {
          sPrice = settup.catering_buffet_price;
        } else if (this.state.settupType === "self_collection") {
          sPrice = settup.catering_self_collection_price;
        }
        settupPrice = parseFloat(settupPrice) + parseFloat(sPrice);
      }
    }

    let addonSelection = this.state.addonSelection;
    if (Object.keys(addonSelection).length > 0) {
      for (var key in addonSelection) {
        for (var key1 in addonSelection[key]) {
          var addonS = addonSelection[key][key1];
          if (addonS.qty > 0) {
            addonPrice =
              parseFloat(addonPrice) +
              parseFloat(addonS.cat_addonvalues_price * addonS.qty);
          }
        }
      }
    }

    var modifierPrice = this.state.modifierPrice;
    var individualmodifierPrice = this.state.individualmodifierPrice * qty;
    productGrandTotal =
      parseFloat(price) +
      parseFloat(additionalPrice) +
      parseFloat(modifierPrice) +
      parseFloat(individualmodifierPrice) +
      parseFloat(equipmentPrice) +
      parseFloat(settupPrice) +
      parseFloat(addonPrice);
    this.setState({ productGrandTotal: productGrandTotal });
  }
  handleUpdateCurrentSetup(settupType) {
    console.log(settupType, "settupType");
    if (settupType === "self_collection") {
      $("#self_collection_text").html("Collection Address");
      cookie.save("self_collection_selected", "1");
    } else {
      cookie.save("self_collection_selected", 0);
      $("#self_collection_text").html("Delivery Address");
    }
    this.setState({ settupType: settupType, settupSelection: [] }, function () {
      this.calculatedOverAllAmount();
    });
  }
  handleChangeText(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  /* Delivery List */
  getDeliverysetup(delivery) {
    cookie.save("self_collection_selected", 0);
    const Deliveryhtml = delivery.map((DelryValue, index) => (
      <li key={index} className="setup_li">
        <div className="custom_checkbox">
          <input
            type="checkbox"
            className="setup_listing_value"
            value={DelryValue.catering_delivery_price}
            data-check-length="1"
            defaultChecked={
              this.state.settupSelection[index] !== "" &&
              typeof this.state.settupSelection[index] !== undefined &&
              typeof this.state.settupSelection[index] !== "undefined"
                ? true
                : false
            }
            onChange={this.handleUpdateSetup.bind(this, DelryValue, index)}
          />
          <span>
            {DelryValue.catering_delivery_title +
            " ( " +
            showPriceValueNew(DelryValue.catering_delivery_price) +
            " ) : " +
            (DelryValue.catering_delivery_description !== "" &&
              DelryValue.catering_delivery_description !== null)
              ? Parser(DelryValue.catering_delivery_description)
              : ""}
          </span>
        </div>
      </li>
    ));
    return Deliveryhtml;
  }
  /* Buffet List */
  getBuffetsetup(buffet) {
    cookie.save("self_collection_selected", 0);
    const Buffethtml = buffet.map((buffetValue, index) => (
      <li key={index} className="setup_li">
        <div className="custom_checkbox">
          <input
            type="checkbox"
            className="setup_listing_value"
            value={buffetValue.catering_buffet_price}
            onChange={this.handleUpdateSetup.bind(this, buffetValue, index)}
          />
          <span>
            {buffetValue.catering_buffet_title +
            " ( " +
            showPriceValueNew(buffetValue.catering_buffet_price) +
            " ) : " +
            (buffetValue.catering_buffet_description !== "" &&
              buffetValue.catering_buffet_description !== null)
              ? Parser(buffetValue.catering_buffet_description)
              : ""}
          </span>
        </div>
      </li>
    ));
    return Buffethtml;
  }

  /* Selef Collection List */
  getSelfCollectionsetup(selfcollection) {
    const Selfcollectionhtml = selfcollection.map(
      (selfCollectionValue, index) => (
        <li key={index} className="setup_li">
          <div className="custom_checkbox">
            <input
              type="checkbox"
              className="setup_listing_value"
              value={selfCollectionValue.catering_self_collection_price}
              onChange={this.handleUpdateSetup.bind(
                this,
                selfCollectionValue,
                index
              )}
            />
            <span>
              {selfCollectionValue.catering_self_collection_title +
              " ( " +
              showPriceValueNew(
                selfCollectionValue.catering_self_collection_price
              ) +
              " ) : " +
              (selfCollectionValue.catering_self_collection_description !==
                "" &&
                selfCollectionValue.catering_self_collection_description !==
                  null)
                ? Parser(
                    selfCollectionValue.catering_self_collection_description
                  )
                : ""}
            </span>
          </div>
        </li>
      )
    );
    return Selfcollectionhtml;
  }
  /* Select Settup */
  handleUpdateSetup = (selectedSettup, settupKey, event) => {
    var parentMod = $(event.target).parents(".additionals-deliverylist");

    var totalModSelect = parentMod.find(
      'input[type="checkbox"]:checked'
    ).length;

    if (totalModSelect > 1) {
      $(event.target).prop("checked", false);
      return false;
    }

    var settupSelection = this.state.settupSelection;
    if (event.target.checked === true) {
      settupSelection[settupKey] = selectedSettup;
    } else {
      delete settupSelection[settupKey];
    }
    this.setState({ settupSelection: settupSelection }, function () {
      this.calculatedOverAllAmount();
    });
  };

  /* Equipment List */
  getEquipmentsetup(equipment) {
    let equipmentSetup = this.state.equipmentSetup;
    const Equipmenthtml = equipment.map((item, index) => (
      <li key={index} className="setup_li">
        <span className="counter qty_bx">
          <span
            onClick={this.equipmentcount.bind(this, index, item, "minus")}
            className="qty_minus"
          >
            -
          </span>
          <strong className="qty_val">
            {equipmentSetup[index] !== "" &&
            typeof equipmentSetup[index] !== undefined &&
            typeof equipmentSetup[index] !== "undefined"
              ? equipmentSetup[index].qty
              : 0}
          </strong>
          <span
            className="qty_plus"
            onClick={this.equipmentcount.bind(this, index, item, "plus")}
          >
            +
          </span>
        </span>
        <p>
          {item.catering_equipment_description +
            " ( " +
            showPriceValueNew(item.catering_equipment_price) +
            " )"}
        </p>
      </li>
    ));
    return Equipmenthtml;
  }
  /* Equipment Selection */
  equipmentcount(index, item, type) {
    var equipmentSetup = this.state.equipmentSetup;
    if (
      equipmentSetup[index] !== "" &&
      typeof equipmentSetup[index] !== undefined &&
      typeof equipmentSetup[index] !== "undefined"
    ) {
      var existEqup = equipmentSetup[index];
      var existQty = existEqup.qty;
      if (type == "minus") {
        existQty = parseInt(existQty) - 1;
        existEqup["qty"] = existQty;
      } else {
        existQty = parseInt(existQty) + 1;
        existEqup["qty"] = existQty;
      }
      if (existQty == 0) {
        delete equipmentSetup[index];
      } else {
        equipmentSetup[index] = existEqup;
      }
    } else {
      if (type == "plus") {
        item["qty"] = 1;
        equipmentSetup[index] = item;
      }
    }
    this.setState({ equipmentSetup: equipmentSetup }, function () {
      this.calculatedOverAllAmount();
    });
  }
  /* Addon Start */
  /* Addon List */
  productAddonsSetup(addonsSetup) {
    const addonshtml = addonsSetup.map((proAddons, index) => (
      <li
        key={index}
        id={"addon-" + index}
        onChange={this.openAddonsPopup.bind(this, index)}
      >
        <div className="custom_checkbox custom-addon-checkbox">
          <input
            type="checkbox"
            className="addonCheck"
            /* checked={this.CheckAddonsValues(index)} */
            defaultChecked={this.CheckAddonsValues(index)}
          />
          <span> {proAddons.cat_addon_title} </span>
          <div className="pkgerror_txt" style={{ display: "none" }}>
            Please select 1 more item!
          </div>
        </div>
        <div
          id={"addonsDet-" + index}
          className="white-popup mfp-hide popup_sec addon_popup addon_popup-catering"
        >
          <div className="pouup_in">
            <div className="addon_poptop">
              <div className="addon_poptop_header">
                <h3>
                  {proAddons.cat_addon_title}
                  {parseInt(proAddons.cat_addon_min_select) === 0 &&
                  parseInt(proAddons.cat_addon_max_select) === 0 ? (
                    ""
                  ) : (
                    <small className="addon-min-max">
                      (Select{" "}
                      {proAddons.cat_addon_min_select +
                        " - Max " +
                        proAddons.cat_addon_max_select}{" "}
                      selections)
                    </small>
                  )}
                </h3>
              </div>
              <div className="addon_listsec">
                {proAddons.cat_addon_selection === "single"
                  ? this.singleAddonPopupList(index, proAddons)
                  : this.multipleAddonPopupList(index, proAddons)}
              </div>
            </div>
            <div className="addon_popbot">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="button common-mfd-closefun"
                title="Go Back"
              >
                Go Back
              </a>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="button common-mfd-closefun"
                title="Continue"
              >
                Continue
              </a>
            </div>
          </div>
        </div>
      </li>
    ));
    return addonshtml;
  }
  openAddonsPopup(addonID) {
    var idText = "#addonsDet-" + addonID;
    window.$.magnificPopup.open({
      items: {
        src: idText,
      },
      type: "inline",
    });
  }
  CheckAddonsValues(addonIndex) {
    if (
      this.state.addonSelection[addonIndex] !== "" &&
      typeof this.state.addonSelection[addonIndex] !== undefined &&
      typeof this.state.addonSelection[addonIndex] !== "undefined"
    ) {
      if (Object.keys(this.state.addonSelection[addonIndex]).length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  singleAddonPopupList(addonKey, proAddons) {
    var proAddonsValues = proAddons.addons_setup_values;
    if (Object.keys(proAddonsValues).length > 0) {
      const AddonsValuehtml = proAddonsValues.map((AddonsValue, index) => (
        <div className="addon_listrw addon_list_main" key={index}>
          <div className="addon_linfo">
            <div className="custom_checkbox">
              <input
                type="checkbox"
                className="addon_list_single"
                onClick={this.addonSingleSelect.bind(
                  this,
                  addonKey,
                  AddonsValue,
                  index,
                  proAddons.cat_addon_max_select
                )}
                /* checked={this.checkAddonChecked(addonKey, index)} */
                defaultChecked={this.checkAddonChecked(addonKey, index)}
              />
              <span>
                <h4>{AddonsValue.cat_addonvalues_title}</h4>
                {AddonsValue.cat_addonvalues_description !== "" ? (
                  <p>{AddonsValue.cat_addonvalues_description}</p>
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
          <div className="addon_lright text-right add_tocart_div">
            <span className="addon_price">
              {showPriceValueNew(AddonsValue.cat_addonvalues_price)}
            </span>
          </div>
        </div>
      ));
      return AddonsValuehtml;
    }
  }
  addonSingleSelect(addonKey, AddonsValue, index, max_select) {
    var addonSelection = this.state.addonSelection;
    var removeClass;
    if (
      addonSelection[addonKey] !== "" &&
      typeof addonSelection[addonKey] !== undefined &&
      typeof addonSelection[addonKey] !== "undefined"
    ) {
      if (
        addonSelection[addonKey]["ad-" + index] !== "" &&
        typeof addonSelection[addonKey]["ad-" + index] !== undefined &&
        typeof addonSelection[addonKey]["ad-" + index] !== "undefined"
      ) {
        delete addonSelection[addonKey]["ad-" + index];
        removeClass = 1;
      } else {
        if (
          parseInt(Object.keys(addonSelection[addonKey]).length) + 1 >
          parseInt(max_select)
        ) {
          $("#addonsDet-" + addonKey + " .addon-min-max").addClass("apply-red");
          //$("#addon-" + addonKey + " .pkgerror_txt").show();
          setTimeout(() => {
            if (
              $("#addonsDet-" + addonKey + " .addon-min-max.apply-red").length >
              0
            ) {
              $("#addon-" + addonKey + " .pkgerror_txt").hide();
              $("#addonsDet-" + addonKey + " .addon-min-max").removeClass(
                "apply-red"
              );
            }
          }, 3000);
        } else {
          AddonsValue["qty"] = 1;
          addonSelection[addonKey]["ad-" + index] = AddonsValue;
          removeClass = 1;
        }
      }
    } else {
      removeClass = 1;
      addonSelection[addonKey] = [];
      AddonsValue["qty"] = 1;
      addonSelection[addonKey]["ad-" + index] = AddonsValue;
    }
    if (removeClass === 1) {
      $("#addonsDet-" + addonKey + " .addon-min-max").removeClass("apply-red");
      $("#addon-" + addonKey + " .pkgerror_txt").hide();
    }
    this.setState({ addonSelection: addonSelection }, function () {
      this.calculatedOverAllAmount();
    });
  }
  checkAddonChecked(addonKey, index) {
    var addonSelection = this.state.addonSelection;
    if (
      addonSelection[addonKey] !== "" &&
      typeof addonSelection[addonKey] !== undefined &&
      typeof addonSelection[addonKey] !== "undefined"
    ) {
      if (
        addonSelection[addonKey]["ad-" + index] !== "" &&
        typeof addonSelection[addonKey]["ad-" + index] !== undefined &&
        typeof addonSelection[addonKey]["ad-" + index] !== "undefined"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  multipleAddonPopupList(addonKey, proAddons) {
    var proAddonsValues = proAddons.addons_setup_values;
    var addonSelection = this.state.addonSelection;
    if (Object.keys(proAddonsValues).length > 0) {
      const AddonsValuehtml = proAddonsValues.map((AddonsValue, index) => {
        var adonQtyEdit = AddonsValue.cat_addonvalues_min_pax;
        var editenabled = 0;
        if (
          addonSelection[addonKey] !== "" &&
          typeof addonSelection[addonKey] !== undefined &&
          typeof addonSelection[addonKey] !== "undefined"
        ) {
          if (
            addonSelection[addonKey]["ad-" + index] !== "" &&
            typeof addonSelection[addonKey]["ad-" + index] !== undefined &&
            typeof addonSelection[addonKey]["ad-" + index] !== "undefined"
          ) {
            adonQtyEdit = addonSelection[addonKey]["ad-" + index].qty;
            editenabled = 1;
          }
        }
        return (
          <div
            className="addon_listrw addon_list_main"
            id={"multiaddon-" + addonKey + "-" + index}
            key={index}
          >
            <div className="addon_linfo">
              <h4>{AddonsValue.cat_addonvalues_title}</h4>
              {AddonsValue.cat_addonvalues_description !== "" ? (
                <p>{AddonsValue.cat_addonvalues_description}</p>
              ) : (
                ""
              )}
            </div>
            <div className="addon-lright-parent">
              <div className="addon_lright text-right add_tocart_div">
                <span
                  className="cross_multi qty_count_sp"
                  style={{ display: editenabled === 1 ? "" : "none" }}
                >
                  {addonSelection[addonKey] !== "" &&
                  typeof addonSelection[addonKey] !== undefined &&
                  typeof addonSelection[addonKey] !== "undefined"
                    ? addonSelection[addonKey]["ad-" + index] !== "" &&
                      typeof addonSelection[addonKey]["ad-" + index] !==
                        undefined &&
                      typeof addonSelection[addonKey]["ad-" + index] !==
                        "undefined"
                      ? "x" + addonSelection[addonKey]["ad-" + index].qty
                      : "x1"
                    : "x1"}
                </span>
                <div className="act-remove-topdiv">
                  <span className="addon_price">
                    {showPriceValueNew(AddonsValue.cat_addonvalues_price)}
                  </span>

                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.addonPlusMinusCart(
                        addonKey,
                        index,
                        AddonsValue.cat_addonvalues_min_pax,
                        "remove"
                      );
                    }}
                    className="delete-act-icon remove_addon"
                    title="Remove"
                    style={{ display: "none" }}
                  >
                    <i className="fa fa-trash-o" data-unicode="f014"></i>
                  </a>
                </div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.addonSubAddtoCart(addonKey, index);
                  }}
                  className="btn btn_green"
                  title="Add to cart"
                >
                  Add to cart
                </a>
              </div>
              <div
                className="addon_lright text-right plue_muns_div"
                style={{ display: "none" }}
              >
                <span
                  className="cross_multi qty_count_sp"
                  style={{ display: "none" }}
                >
                  {parseInt(adonQtyEdit) > 0 ? "x" + adonQtyEdit : "x1"}
                </span>

                <span className="addon_price">
                  {showPriceValueNew(AddonsValue.cat_addonvalues_price)}
                </span>

                <span className="counter qty_bx">
                  <span
                    className="qty_minus"
                    onClick={this.addonPlusMinusCart.bind(
                      this,
                      addonKey,
                      index,
                      AddonsValue.cat_addonvalues_min_pax,
                      "minus"
                    )}
                  >
                    -
                  </span>
                  <strong className="addon_qty_lable">{adonQtyEdit}</strong>
                  <span
                    className="qty_plus"
                    onClick={this.addonPlusMinusCart.bind(
                      this,
                      addonKey,
                      index,
                      AddonsValue.cat_addonvalues_min_pax,
                      "plus"
                    )}
                  >
                    +
                  </span>
                </span>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.addonDoneCart(addonKey, index, AddonsValue);
                  }}
                  className="btn btn_green"
                  title="Add to cart"
                >
                  Done
                </a>
              </div>
            </div>
          </div>
        );
      });
      return AddonsValuehtml;
    }
  }
  addonSubAddtoCart(addonKey, index) {
    $("#multiaddon-" + addonKey + "-" + index + " .qty_count_sp").hide();
    $("#multiaddon-" + addonKey + "-" + index + " .remove_addon").hide();
    $("#multiaddon-" + addonKey + "-" + index + " .add_tocart_div").hide();
    $("#multiaddon-" + addonKey + "-" + index + " .plue_muns_div").show();
  }
  addonPlusMinusCart(addonKey, index, min_pax, type) {
    var addonDiv = $("#multiaddon-" + addonKey + "-" + index);
    var qty = addonDiv.find(".addon_qty_lable").text();
    var currentQty = qty;
    var minReached = 0;
    if (type === "remove") {
      addonDiv.find(".qty_count_sp").hide();
      addonDiv.find(".addon_qty_lable").text(min_pax);
      addonDiv.find(".remove_addon").hide();
      this.addonRemoveCart(addonKey, index);
    } else if (type === "minus") {
      currentQty = currentQty - 1;
      if (min_pax > currentQty) {
        addonDiv.find(".plue_muns_div").hide();
        addonDiv.find(".add_tocart_div").show();
        minReached = 1;
        this.addonRemoveCart(addonKey, index);
      }
    } else {
      currentQty = parseInt(currentQty) + 1;
    }
    if (minReached === 0) {
      addonDiv.find(".addon_qty_lable").text(currentQty);
    }
  }
  addonDoneCart(addonKey, index, AddonsValue) {
    var addonSelection = this.state.addonSelection;
    var addonDiv = $("#multiaddon-" + addonKey + "-" + index);
    var addonQty = addonDiv.find(".addon_qty_lable").text();
    if (parseInt(addonQty) > 0) {
      if (
        addonSelection[addonKey] !== "" &&
        typeof addonSelection[addonKey] !== undefined &&
        typeof addonSelection[addonKey] !== "undefined"
      ) {
        AddonsValue["qty"] = addonQty;
        addonSelection[addonKey]["ad-" + index] = AddonsValue;
      } else {
        addonSelection[addonKey] = [];
        AddonsValue["qty"] = addonQty;
        addonSelection[addonKey]["ad-" + index] = AddonsValue;
      }
    } else {
      if (
        addonSelection[addonKey] !== "" &&
        typeof addonSelection[addonKey] !== undefined &&
        typeof addonSelection[addonKey] !== "undefined"
      ) {
        delete addonSelection[addonKey]["ad-" + index];
      }
    }

    addonDiv.find(".add_tocart_div").show();
    if (parseInt(addonQty) > 0) {
      addonDiv.find(".qty_count_sp").show();
      addonDiv.find(".remove_addon").show();
    } else {
      addonDiv.find(".qty_count_sp").hide();
      addonDiv.find(".remove_addon").hide();
    }
    addonDiv.find(".plue_muns_div").hide();
    this.setState({ addonSelection: addonSelection }, function () {
      this.calculatedOverAllAmount();
    });
  }
  addonRemoveCart(addonKey, index) {
    var addonSelection = this.state.addonSelection;
    if (
      addonSelection[addonKey] !== "" &&
      typeof addonSelection[addonKey] !== undefined &&
      typeof addonSelection[addonKey] !== "undefined"
    ) {
      delete addonSelection[addonKey]["ad-" + index];
    }
    this.setState({ addonSelection: addonSelection }, function () {
      this.calculatedOverAllAmount();
    });
  }
  
  proModName(modifier) {
	  let modifierNameTxt = '';
	  if(modifier.pro_modifier_alias_name != '' && modifier.pro_modifier_alias_name != null) {
		  modifierNameTxt = stripslashes(modifier.pro_modifier_alias_name);
	  } else if(modifier.pro_modifier_name != '' && modifier.pro_modifier_name != null) {
		  modifierNameTxt = stripslashes(modifier.pro_modifier_name);
	  }
	  
	  return (modifierNameTxt);
  }
  
  proModValName(modifier) {
	  let modifierNameTxt = '';
	  if(modifier.pro_modifier_value_alias_name != '' && modifier.pro_modifier_value_alias_name != null) {
		  modifierNameTxt = stripslashes(modifier.pro_modifier_value_alias_name);
	  } else if(modifier.pro_modifier_value_name != '' && modifier.pro_modifier_value_name != null) {
		  modifierNameTxt = stripslashes(modifier.pro_modifier_value_name);
	  }
	  
	  return (modifierNameTxt);
  }
  
  /* Addon End */
  modfierValuelistMain(
    modifieirs,
    product_quantity_inc,
    cindex,
    mindex,
    checkProSelected
  ) {
    if (modifieirs.length > 0) {
      var setMultimodifiers = this.state.setMultimodifiers;
      var selectedPro = this.state.selectedPro;
      return modifieirs.map((modifier, index) => {
        var modifier_list = [];
        if (
          setMultimodifiers[selectedPro] !== "" &&
          typeof setMultimodifiers[selectedPro] !== undefined &&
          typeof setMultimodifiers[selectedPro] !== "undefined"
        ) {
          if (
            setMultimodifiers[selectedPro][cindex] !== "" &&
            typeof setMultimodifiers[selectedPro][cindex] !== undefined &&
            typeof setMultimodifiers[selectedPro][cindex] !== "undefined"
          ) {
            if (
              setMultimodifiers[selectedPro][cindex][mindex] !== "" &&
              typeof setMultimodifiers[selectedPro][cindex][mindex] !==
                undefined &&
              typeof setMultimodifiers[selectedPro][cindex][mindex] !==
                "undefined"
            ) {
              modifier_list = setMultimodifiers[selectedPro][cindex][mindex];
            }
          }
        }
        /* if (checkProSelected.selected === true) {
          if (checkProSelected.modifiers.length > modifier_list.length) {
            modifier_list = [];
            checkProSelected.modifiers.map((itemM, index) => {
              modifier_list.push(index);
            });
          }
        } else { */
        if (modifier_list.length === 0) {
          modifier_list = [0];
        }
        /* } */

        var modifiersList = modifier_list.map((mod, index) => {
          return (
            <div
              className="form-group package_checkitem mdfr_list_divlcs"
              key={index}
              style={{
                display: checkProSelected.selected === true ? "" : "none",
              }}
              data-mdfmin={modifier.pro_modifier_min_select}
              data-mdfmax={modifier.pro_modifier_max_select}
              data-modifierdetails={
                modifier.pro_modifier_name + "~" + modifier.pro_modifier_id
              }
            >
              <p>
                <i>{this.proModName(modifier)}</i>
                <br />
                (Choice of {modifier.pro_modifier_min_select} -{" "}
                {modifier.pro_modifier_max_select} of the followings)
              </p>
              <div className="pkgerror_txt" style={{ display: "none" }}>
                {" "}
                Please select 1 more item(s)!
              </div>

              {this.modfierValuelistSub(
                modifier.modifiers_values,
                index,
                checkProSelected
              )}
            </div>
          );
        });
        return (
          <>
            {modifiersList}
            {product_quantity_inc === "1" && (
              <div
                className="modi_product_quantity_inc"
                style={{
                  display: checkProSelected.selected === true ? "" : "none",
                }}
              >
                <span className="counter qty_bx">
                  <span
                    className="qty_minus"
                    onClick={this.incModifierQty.bind(
                      this,
                      cindex,
                      mindex,
                      "minus"
                    )}
                  >
                    -
                  </span>
                  <strong className="qty_val">{modifier_list.length}</strong>
                  <span
                    className="qty_plus"
                    onClick={this.incModifierQty.bind(
                      this,
                      cindex,
                      mindex,
                      "plus"
                    )}
                  >
                    +
                  </span>
                </span>
                <br />
                <br />
              </div>
            )}
          </>
        );
      });
    }
  }
  modfierValuelistSub(modifiers_values, indexkey, checkProSelected) {
    if (modifiers_values.length > 0) {
      return modifiers_values.map((values, index) => {
        var checkModifierVal = { selected: false };
        if (checkProSelected.selected === true) {
          if (
            checkProSelected.modifiers[indexkey] !== "" &&
            typeof checkProSelected.modifiers[indexkey] !== undefined &&
            typeof checkProSelected.modifiers[indexkey] !== "undefined"
          ) {
            checkModifierVal = this.checkModifierVal(
              checkProSelected.modifiers[indexkey].modifiers_values,
              values.pro_modifier_value_id
            );
          }
        }

        return (
          <div className="custom_checkbox" key={index}>
            <input
              type="checkbox"
              className={
                "modifiers_valuecheck mod_" + values.pro_modifier_value_id
              }
              value={
                values.pro_modifier_value_id +
                "~" +
                values.pro_modifier_value_name
              }
              onChange={this.checkMinMaxMod.bind(this)}
              data-modprice={values.pro_modifier_value_price}
              data-isindividual={values.pro_modifier_individual}
              data-isdefault={values.pro_modifier_value_is_default}
              defaultChecked={
                checkProSelected.selected === true
                  ? checkModifierVal.selected === true
                    ? true
                    : false
                  : false
              }
              data-kkk={
                checkProSelected.selected === true
                  ? checkModifierVal.selected === true
                    ? "true"
                    : "false"
                  : "false"
              }
            />{" "}
            <span>
              {/* Image Section */}
              {modifiers_values.pro_modifier_value_image ? (
                <img
                  src={
                    this.state.prCommon.tag_image_source +
                    "/" +
                    modifiers_values.pro_modifier_value_image
                  }
                  alt=""
                />
              ) : (
                ""
              )}
			  {this.proModValName(values)}
              {/*values.pro_modifier_value_name*/}
              <p>
                {parseInt(values.pro_modifier_value_price) > 0
                  ? "(" + "$" + values.pro_modifier_value_price + ")"
                  : ""}
              </p>
            </span>
          </div>
        );
      });
    }
  }

  incModifierQty(cindex, mindex, type) {
    var setMultimodifiers = this.state.setMultimodifiers;
    var selectedPro = this.state.selectedPro;

    if (
      setMultimodifiers[selectedPro] !== "" &&
      typeof setMultimodifiers[selectedPro] !== undefined &&
      typeof setMultimodifiers[selectedPro] !== "undefined"
    ) {
      if (
        setMultimodifiers[selectedPro][cindex] !== "" &&
        typeof setMultimodifiers[selectedPro][cindex] !== undefined &&
        typeof setMultimodifiers[selectedPro][cindex] !== "undefined"
      ) {
        if (
          setMultimodifiers[selectedPro][cindex][mindex] !== "" &&
          typeof setMultimodifiers[selectedPro][cindex][mindex] !== undefined &&
          typeof setMultimodifiers[selectedPro][cindex][mindex] !== "undefined"
        ) {
          var modiArryList = [];
          if (type === "plus") {
            var totalList =
              parseInt(setMultimodifiers[selectedPro][cindex][mindex].length) +
              1;
          } else if (type === "minus") {
            var totalList =
              parseInt(setMultimodifiers[selectedPro][cindex][mindex].length) -
              1;
          }
          if (totalList <= 0) {
            totalList = 1;
          }

          for (let index = 0; index < totalList; index++) {
            modiArryList.push(index);
          }
          setMultimodifiers[selectedPro][cindex][mindex] = modiArryList;
        }
      }
    }
    this.setState({ setMultimodifiers: setMultimodifiers }, function () {
      $("#" + cindex + "s #pro-" + mindex + " .mdfr_list_divlcs").show();
    });
  }

  qtyoptionContent(cindex, pindex, product_details, checkProSelected) {
    var minQty =
      product_details.product_min_pax > 0 ? product_details.product_min_pax : 0;

    return (
      <div
        className="increase_qty"
        style={{ display: checkProSelected.selected === true ? "" : "none" }}
      >
        <div className="text_center">
          {(product_details.product_tag || []).map((tag, index) => {
            return (
              <img
                src={this.state.prCommon.tag_image_source + tag.pro_tag_image}
                alt={tag.pro_tag_name}
                key={index}
              />
            );
          })}
          <p className="text_center">
            <b
              className="popup_price_show"
              style={{
                display: "none",
              }}
            >
              $
              {parseInt(product_details.product_price) > 0
                ? product_details.product_price
                : ""}
            </b>
          </p>
          <span
            className="counter combo_qtycount qty_bx"
            data-qtyval={
              checkProSelected.selected === true ? checkProSelected.qty : minQty
            }
          >
            <span
              className="qty_minus"
              onClick={(e) => {
                e.preventDefault();
                this.incComboQty(cindex, pindex, "minus");
              }}
            >
              -
            </span>
            <strong className="qty_val proqty_input">
              {checkProSelected.selected === true
                ? checkProSelected.qty
                : minQty}
            </strong>
            <span
              className="qty_plus"
              onClick={(e) => {
                e.preventDefault();
                this.incComboQty(cindex, pindex, "inc");
              }}
            >
              +
            </span>
          </span>
          <span
            className={
              "error " + product_details.product_id + "_" + "count_exist_error"
            }
            style={{
              display: "none",
            }}
          >
            Sorry!. maximum of selected item is{" "}
            {product_details.product_max_pax} only.
          </span>
        </div>
        <br />
      </div>
    );
  }

  incComboQty(cindex, pindex, type) {
    console.log(cindex, pindex, type);
    var productData = this.state.productData[this.state.selectedPro];
    var comboDetails = productData.set_menu_component[cindex];
    var selPro = productData.set_menu_component[cindex].product_details[pindex];
    var selProCombo = $("#" + cindex + "s");
    var selProDiv = selProCombo.find("#pro-" + pindex);
    var min_select = parseInt(comboDetails.menu_component_min_select);
	/*var isdefault = '';*/

    if (selPro.modifiers.length > 0 && type !== "addm" && type !== "remove") {
      selProDiv.find(".mdfr_list_divlcs").show();
      selProDiv.find(".custom_logo_banner").show();
      selProDiv.find(".modifier-btn-grp").show();
      selProDiv.find(".modi_product_quantity_inc").show();
      selProDiv.find(".select_product").hide();
	  selProDiv.find(".modifiers_valuecheck").each(function () {
		  if($(this).attr("data-isdefault") === 'Yes') {
			  $(this).prop("checked", true);
		  }
	  });
      return false;
    }

    if (type === "remove") {
      selProDiv.removeClass("selected");
      selProDiv.find(".custom_checkbox").show();
      selProDiv.find(".prd_close").hide();
      selProDiv.find(".custom_logo_banner").hide();
      selProDiv.find(".custom_banner_text").val("");
      selProDiv.find(".imagename").html("");
      selProDiv.find(".modi_product_quantity_inc").hide();
      selProDiv
        .find(".mdfr_list_divlcs")
        .find('input[type="checkbox"]')
        .prop("checked", false);
    } else {
      selProDiv.addClass("selected");
      if (type !== "addm") {
        selProDiv.find(".custom_checkbox").hide();
      }
      selProDiv.find(".custom_logo_banner").show();
      selProDiv.find(".mdfr_list_divlcs").hide();
      selProDiv.find(".modi_product_quantity_inc").hide();
      selProDiv.find(".modifier-btn-grp").hide();
      selProDiv.find(".prd_close").show();
    }

    var SelectedQty = 0;
    selProCombo.find(".compo_minmax_maindiv").each(function () {
      if (parseInt($(this).attr("data-qty")) > 0) {
        SelectedQty =
          parseInt(SelectedQty) + parseInt($(this).attr("data-qty"));
      }
    });

    var SelectedExtraQty = 0;
    selProCombo.find(".compo_minmax_maindiv").each(function () {
      if (parseInt($(this).attr("data-extraqty")) > 0) {
        SelectedExtraQty =
          parseInt(SelectedExtraQty) + parseInt($(this).attr("data-extraqty"));
      }
    });

    var Qty = parseInt(selProDiv.attr("data-qty"));
    var MinQty = parseInt(selProDiv.attr("data-minqty"));
    var extraQty = parseInt(selProDiv.attr("data-extraqty"));
    var incquantity = parseInt(selProDiv.attr("data-incquantity"));
    var IsModifiers = parseInt(selProDiv.attr("data-modifiers"));
    if (IsModifiers === 1) {
      extraQty = 0;
    }

    if (parseInt(incquantity) <= 0) {
      incquantity = 1;
    }

    if (type === "add" || type === "inc" || type === "addm") {
      if (IsModifiers === 1) {
        Qty = 1;
      } else {
        Qty = parseInt(Qty) + parseInt(incquantity);
      }

      if (MinQty > Qty) {
        Qty = MinQty;
      }
    } else if (type === "minus") {
      Qty = parseInt(Qty) - parseInt(incquantity);
      if (parseInt(MinQty) > parseInt(Qty)) {
        Qty = 0;
      }
    } else if (type === "remove") {
      Qty = 0;
    }
    var applyextraqty = 0;
    var applyPrice = 0;
    var selectProComboInc = 0;
    if (type === "add" || type === "addm" || type === "inc") {
      selectProComboInc = parseInt(SelectedQty) + parseInt(incquantity);
    } else {
      selectProComboInc = parseInt(SelectedQty) - parseInt(incquantity);
    }

    if (comboDetails.menu_component_apply_price === "1") {
      applyextraqty = Qty;

      applyPrice = applyextraqty * selPro.product_price;
    } else if (min_select < selectProComboInc) {
      var overAllExtra = parseInt(selectProComboInc) - parseInt(min_select);
      var currentExtra = overAllExtra - SelectedExtraQty;
      applyextraqty = parseInt(currentExtra) + parseInt(extraQty);
      applyPrice = applyextraqty * selPro.product_price;
    }

    if (Qty === 0) {
      applyextraqty = 0;
      applyPrice = 0;
    }

    selProDiv.attr({
      "data-qty": Qty,
      "data-extraqty": applyextraqty,
      "data-extraprice": applyPrice,
    });
    if (applyextraqty > 0) {
      var totalModAddPrice = 0;
      if (selProDiv.find(".mdfr_list_divlcs").length > 0) {
        selProDiv.find(".mdfr_list_divlcs").each(function () {
          $(this)
            .find('input[type="checkbox"]:checked')
            .each(function () {
              if ($(this).attr("data-modprice") > 0) {
                totalModAddPrice =
                  parseFloat(totalModAddPrice) +
                  parseFloat($(this).attr("data-modprice"));
              }
            });
        });
      }

      selProDiv
        .find(".popup_price")
        .show()
        .html(
          showPriceValueNew(
            parseFloat(applyPrice) + parseFloat(totalModAddPrice)
          ) + " / pax"
        );
    } else {
      if (min_select > selectProComboInc) {
        selProCombo.find(".compo_minmax_maindiv").attr({
          "data-extraqty": 0,
          "data-extraprice": 0,
        });
        selProCombo.find(".popup_price").hide();
      } else {
        selProDiv.find(".popup_price").hide();
      }
    }
    if (Qty === 0) {
      selProDiv.removeClass("selected");
      selProDiv.find(".custom_checkbox").show();
      selProDiv.find(".prd_close").hide();
      selProDiv.find(".increase_qty").hide();
    }
    selProDiv.find(".proqty_input").html(Qty);
    if (selPro.product_quantity_inc === "1" && Qty > 0) {
      selProDiv.find(".increase_qty").show();
      Qty = selProDiv.find(".proqty_input").text();
    }

    var additionalPrice = 0;
    var modifierPrice = 0;
    var isindividual = 0;
    $(".combo_set").each(function () {
      $(this)
        .find(".compo_minmax_maindiv")
        .each(function () {
          if (parseInt($(this).attr("data-extraprice")) > 0) {
            additionalPrice =
              parseFloat(additionalPrice) +
              parseFloat($(this).attr("data-extraprice"));
          }
          $(this)
            .find(".mdfr_list_divlcs")
            .each(function () {
              $(this)
                .find('input[type="checkbox"]:checked')
                .each(function () {
                  if ($(this).attr("data-modprice") > 0) {
                    if ($(this).attr("data-isindividual") === "No") {
                      isindividual =
                        parseFloat(isindividual) +
                        parseFloat($(this).attr("data-modprice"));
                    } else {
                      modifierPrice =
                        parseFloat(modifierPrice) +
                        parseFloat($(this).attr("data-modprice"));
                    }
                  }
                });
            });
        });
    });
    this.setState(
      {
        additionalPrice: additionalPrice,
        modifierPrice: modifierPrice,
        individualmodifierPrice: isindividual,
      },
      function () {
        this.calculatedOverAllAmount();
      }
    );
  }

  completeModifiers(cindex, pindex) {
    var parentCombo = $("#" + cindex + "s");
    var parentProduct = parentCombo.find("#pro-" + pindex);
    var merror = 0;
    parentProduct
      .find(".individual_combo_pro")
      .find(".mdfr_list_divlcs")
      .each(function () {
        var minSelect = $(this).attr("data-mdfmin");
        var total_Mod_Select = $(this).find('input[type="checkbox"]:checked');
        if (minSelect > total_Mod_Select.length) {
          $(this)
            .find(".pkgerror_txt")
            .show()
            .html("Please select minimum " + minSelect + " item(s).");
          merror++;
        } else {
          $(this).find(".pkgerror_txt").hide().html("");
        }
      });
    if (merror === 0) {
      this.incComboQty(cindex, pindex, "addm");
    }
  }
  cancelModifiers(cindex, pindex) {
    var parentCombo = $("#" + cindex + "s");
    var parentProduct = parentCombo.find("#pro-" + pindex);
    parentProduct.find(".mdfr_list_divlcs").hide();
    parentProduct.find(".modi_product_quantity_inc").hide();
    parentProduct.find(".custom_logo_banner").hide();
    parentProduct.find(".modifier-btn-grp").hide();
    parentProduct.find(".popup_price").hide().html("");
    parentProduct.find(".select_product").show();
  }
  checkMinMaxMod(event) {
    var parentMod = $(event.target).parents(".mdfr_list_divlcs");
    var totalModSelect = parentMod.find(
      'input[type="checkbox"]:checked'
    ).length;
    var maxMod = parentMod.attr("data-mdfmax");
    if (maxMod < totalModSelect) {
      parentMod
        .find(".pkgerror_txt")
        .show()
        .html("Sorry!. maximum of selected item is " + maxMod + " only.");
      $(event.target).prop("checked", false);
    } else {
      parentMod.find(".pkgerror_txt").hide().html("");
    }
    return false;
  }
  /* Custom Logo Start */
  /* Upload Custom Logo */
  handlecustomLogoChange(cindex, pindex, event) {
    if (event.target.name === "custom_logo_banner") {
      var file = event.target.files[0];
      if (
        file.name !== "" &&
        typeof file.name !== undefined &&
        typeof file.name !== "undefined"
      ) {
        var file_name = file.name ? file.name : "";
        var postObject = {};
        postObject = {
          app_id: appId,
          product_image: file.name,
          image: file,
        };

        let formData = new FormData();
        for (let k in postObject) {
          formData.append(k, postObject[k]);
        }
        this.setState({ cindex: cindex, pindex: pindex });
        this.props.GetUploadImage(formData);
      }
    }
  }
  /* Remove Custom Logo */
  removecustomLogo(cindex, pindex) {
    let formData = new FormData();

    var postObject = {};
    postObject = {
      app_id: appId,
      imageName: $("#" + cindex + "s #pro-" + pindex)
        .find(".imagename")
        .text(),
    };
    for (let k in postObject) {
      formData.append(k, postObject[k]);
    }
    this.setState({ cindex: cindex, pindex: pindex });
    this.props.getDeleteImage(formData);
  }
  /* Custom Logo End */

  addToCartCombo() {
    var proDetail = this.state.productData[this.state.selectedPro];
    var product_unit_price =
      parseFloat(proDetail.product_price) +
      parseFloat(this.state.individualmodifierPrice);

    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    var productState = this.state.productState;
    var currentSlot = productState.currentSlot;
    var startTime = $("#slot_" + currentSlot + " .start_time").val();
    var endTime = $("#slot_" + currentSlot + " .end_time").val();
    var product_qty = this.state.selectpaxcount;
    /* Validation Part */
    var validate = true;
    if (productState.postal_code === "") {
      this.props.sateValChange("postalcode_error", 1);
      validate = false;
    }
    if (validate === true) {
      if (productState.orderDate === "") {
        this.props.sateValChange("orderdate_error", 1);
        validate = false;
      }
    }
    if (validate === true) {
      if (
        typeof startTime !== undefined &&
        typeof startTime !== "undefined" &&
        typeof endTime !== undefined &&
        typeof endTime !== "undefined"
      ) {
        if (startTime === "") {
          this.props.sateValChange("startslot_error", 1);
        }
        if (endTime === "") {
          this.props.sateValChange("endslot_error", 1);
        }
        if (startTime === "" || endTime === "") {
          if ($("body").width() <= 980) {
            $("html, body").animate(
              {
                scrollTop: $(".end_time_error").offset().top - 150,
              },
              500
            );
          }
          validate = false;
        }
      } else {
        this.props.sateValChange("orderslot_error", 1);
        validate = false;
      }
    }

    if (validate === true) {
      $(".product-lhs .products_list .combo_set").each(function () {
        var comboerror = 0;
        var menuID = this.id.replace("s", "");
        var menuSelected = $(this).find(".compo_minmax_maindiv");
        var productMenu = proDetail.set_menu_component[menuID];
        var minCombo = productMenu.menu_component_min_select;
        var maxCombo = productMenu.menu_component_max_select;

        var totalComboSelect = 0;
        menuSelected.each(function () {
          if (parseInt($(this).attr("data-qty")) > 0) {
            totalComboSelect =
              parseInt(totalComboSelect) + parseInt($(this).attr("data-qty"));
          }
        });
        if (minCombo > totalComboSelect) {
          $(this)
            .find(".min_product_err")
            .show()
            .html("Please select minimum " + minCombo + " item(s).");
          comboerror++;
          validate = false;
        }

        if (comboerror === 0) {
          if (totalComboSelect > maxCombo) {
            $(this)
              .find(".min_product_err")
              .show()
              .html(
                "Sorry!. maximum of selected item is " + maxCombo + " only."
              );
            comboerror++;
            validate = false;
          }
        }
        if (comboerror === 0) {
          $(this).find(".min_product_err").hide();
        }
      });
    }

    let settupSelection = this.state.settupSelection;
    if (
      this.state.settupType !== "" &&
      typeof this.state.settupType !== undefined &&
      typeof this.state.settupType !== "undefined"
    ) {
      if (Object.keys(settupSelection).length === 0) {
        var settupType = "";
        if (this.state.settupType === "delivery") {
          settupType = "Delivery";
        } else if (this.state.settupType === "buffet") {
          settupType = "Buffet";
        } else if (this.state.settupType === "self_collection") {
          settupType = "Self Collection";
        }
        validate = false;
        $(".SetupError")
          .show()
          .html("Please select your " + settupType + " setup");
      } else {
        $(".SetupError").hide().html("");
      }
    } else {
      $(".SetupError").hide().html("");
    }

    if (validate === true) {
      /* Setup */
      var postSetup = [];
      if (settupSelection.length > 0) {
        for (var key in settupSelection) {
          var settup = settupSelection[key];
          var sPrice = 0;
          var settupName = "";
          var settupId = "";
          var settupType = "";
          var settupdescription = "";
          if (this.state.settupType === "delivery") {
            sPrice = settup.catering_delivery_price;
            settupType = "Delivery";
            settupId = settup.catering_delivery_setup_id;
            settupName = settup.catering_delivery_title;
            settupdescription = settup.catering_delivery_description;
          } else if (this.state.settupType === "buffet") {
            sPrice = settup.catering_buffet_price;
            settupType = "Buffet";
            settupId = settup.catering_buffet_setup_id;
            settupName = settup.catering_buffet_title;
            settupdescription = settup.catering_buffet_description;
          } else if (this.state.settupType === "self_collection") {
            sPrice = settup.catering_self_collection_price;
            settupType = "Self Collection";
            settupId = settup.catering_self_collection_setup_id;
            settupName = settup.catering_self_collection_title;
            settupdescription = settup.catering_self_collection_description;
          }
          postSetup.push({
            setupid: settupId,
            setupname: settupName,
            setupdescription: settupdescription,
            setupprice: sPrice,
            setupqty: 1,
            setuptype: settupType,
          });
        }
      }
      /* Equipment Setups */
      var equipmentSetups = [];
      let equipmentSetup = this.state.equipmentSetup;
      if (equipmentSetup.length > 0) {
        for (var key2 in equipmentSetup) {
          var equipment = equipmentSetup[key2];
          equipmentSetups.push({
            equipmentid: equipment.catering_equipment_setup_id,
            equipmentname: equipment.catering_equipment_title,
            equipmentdescription: equipment.catering_equipment_description,
            equipmentprice: equipment.catering_equipment_price,
            equipmentquantity: equipment.qty,
          });
        }
      }

      /* Addons Settup */
      var proaddonssetup = proDetail.addons_setup;
      var postAddons = [];
      let addonSelection = this.state.addonSelection;
      if (Object.keys(addonSelection).length > 0) {
        for (var key in addonSelection) {
          var addonM = addonSelection[key];
          var setup_values = [];
          for (var key1 in addonSelection[key]) {
            var addonS = addonSelection[key][key1];
            if (addonS.qty > 0) {
              setup_values.push({
                addon_setup_val_id: addonS.cat_addonvalues_id,
                addon_setup_val_title: addonS.cat_addonvalues_title,
                addon_setup_val_desc: addonS.cat_addonvalues_description,
                addon_setup_val_price: addonS.cat_addonvalues_price,
                addon_setup_val_qty: addonS.qty,
              });
            }
          }
          if (setup_values.length > 0) {
            var selectAddon = proaddonssetup[key];
            postAddons.push({
              addon_setup_id: selectAddon.product_catering_addons_id,
              addon_setup_title: selectAddon.cat_addon_title,
              addon_setup_selection: selectAddon.cat_addon_selection,
              addons_setup_values: setup_values,
            });
          }
        }
      }

      /* Menu Set Product Details */
      var menuSet = [];
      var Extra_Price = 0;
      $(".product-lhs .products_list .combo_set").each(function () {
        var menuID = this.id.replace("s", "");
        var menuSelected = $(this).find(".compo_minmax_maindiv.selected");
        if (menuSelected.length > 0) {
          var productMenu = proDetail.set_menu_component[menuID];
          var productdetails = [];
          menuSelected.each(function () {
            var proID = this.id.replace("pro-", "");
            var cartextraqty = parseInt($(this).attr("data-extraqty"));
            var cartextraprice = parseFloat($(this).attr("data-extraprice"));
            var proQty = parseFloat($(this).attr("data-qty"));

            var custom_banner_text = $(this).find(".custom_banner_text").val();
            var custom_banner_text = $(this).find(".custom_banner_text").val();
            var imagename = $(this)
              .find(".file_details")
              .find(".imagename")
              .text();
            if (parseFloat(cartextraprice) > 0) {
              Extra_Price =
                parseFloat(Extra_Price) + parseFloat(cartextraprice);
            }

            var comboMdfSet = [];
            if ($(this).find(".mdfr_list_divlcs").length > 0) {
              $(this)
                .find(".mdfr_list_divlcs")
                .each(function () {
                  if (
                    $(this).find('input[type="checkbox"]:checked').length > 0
                  ) {
                    var modifierdetails = $(this)
                      .attr("data-modifierdetails")
                      .split("~");
                    var comboMdfValueSet = [];
                    $(this)
                      .find('input[type="checkbox"]:checked')
                      .each(function () {
                        var modSelectVal = $(this).val().split("~");
                        comboMdfValueSet.push({
                          modifier_value_name: modSelectVal[1],
                          modifier_value_id: modSelectVal[0],
                          modifier_value_price: parseFloat(
                            $(this).attr("data-modprice")
                          ).toFixed(2),
                          modifier_value_qty: 1,
                        });
                        if (
                          parseFloat($(this).attr("data-modprice")) > 0 &&
                          cartextraqty > 0
                        ) {
                          cartextraprice =
                            parseFloat(cartextraprice) +
                            parseFloat($(this).attr("data-modprice"));
                        }
                      });
                    comboMdfSet.push({
                      modifier_id: modifierdetails[1],
                      modifier_name: modifierdetails[0],
                      modifiers_values: comboMdfValueSet,
                    });
                  }
                });
            }
            productdetails.push({
              product_id: productMenu.product_details[proID].product_id,
              product_name:
                productMenu.product_details[proID].product_alias !== ""
                  ? productMenu.product_details[proID].product_alias
                  : productMenu.product_details[proID].product_name,
              product_sku: productMenu.product_details[proID].product_sku,
              product_price:
                cartextraqty > 0
                  ? productMenu.product_details[proID].product_price
                  : 0,
              product_qty: proQty,
              modifiers: comboMdfSet,
              cart_menu_component_product_extra_qty: cartextraqty,
              cart_menu_component_product_extra_price:
                cartextraprice.toFixed(2),
              custom_text:
                custom_banner_text !== "" &&
                typeof custom_banner_text !== undefined &&
                typeof custom_banner_text !== "undefined"
                  ? custom_banner_text
                  : "",
              custom_logo:
                imagename !== "" &&
                typeof imagename !== undefined &&
                typeof imagename !== "undefined"
                  ? imagename
                  : "",
            });
          });

          menuSet.push({
            menu_component_id: productMenu.menu_component_id,
            menu_component_name: productMenu.menu_component_name,
            min_max_flag: 0,
            product_details: productdetails,
          });
        }
      });
      product_unit_price =
        parseFloat(product_unit_price) + parseFloat(Extra_Price);
      var additionalPrice = this.state.additionalPrice;

      var grand_Total =
        parseFloat(product_unit_price * product_qty) +
        parseFloat(this.state.modifierPrice);

      /* this.state.productGrandTotal */
      var postObject = {
        app_id: appId,
        product_id: proDetail.product_id,
        product_qty: product_qty,
        outlet_id: cookie.load("orderOutletId"),
        outletzone_id: cookie.load("catering_zone_id"),
        product_min_qty: proDetail.product_min_pax,
        availability_id: cateringId,
        product_name: proDetail.product_name,
        product_total_price: grand_Total,
        product_unit_price: product_unit_price,
        product_with_modifier_price: this.state.modifierPrice,
        product_remarks: this.state.specialInstruction,
        product_image: proDetail.product_thumbnail,
        product_sku: proDetail.product_sku,
        product_slug: proDetail.product_slug,
        individual: "yes",
        customer_id: customerId,
        reference_id: customerId === "" ? getReferenceID() : "",
        product_edit_enable: "No",
        breaktime_enable: "Yes",
        breaktime_count: 1,
        breaktime_list: startTime + "-" + endTime,
        breaktime_indexflag: currentSlot,
        breaktime_started: startTime,
        breaktime_ended: endTime,
        product_special_notes: this.state.specialInstruction,
        cart_catering_order_date: productState.orderDate,
        setup: JSON.stringify(postSetup),
        equipment: JSON.stringify(equipmentSetups),
        addons_setup: JSON.stringify(postAddons),
        menu_set_component: JSON.stringify(menuSet),
        modifiers: [],
      };
      showLoader("add_to_cart", "class");
      axios
        .post(apiUrlCtrng + "cart/insert", qs.stringify(postObject))
        .then((res) => {
          hideLoader("add_to_cart", "class");
          if (res.data.status === "ok") {
            if (this.state.cartID !== "") {
              swal("Success!", "Your Cart Updated Successfully", "success");
            } else {
              swal("Success!", res.data.message, "success");
            }
            if (
              this.state.cartID !== "" &&
              typeof this.state.cartID !== undefined &&
              typeof this.state.cartID !== "undefined"
            ) {
              this.deleteCartDetails();
            }
            this.props.history.push(
              "/catering-success/" +
                this.state.selectedCategory +
                "/" +
                this.state.selectedSubCategory
            );
          } else if (res.data.status === "error") {
            swal("Error!", res.data.message, "error");
          }
        });
    } else {
      setTimeout(() => {
        this.scrollPreticularDiv();
      }, 500);
    }
  }
  deleteCartDetails() {
    var editcartId = this.state.cartID;
    var cartItem = this.state.cartdetails[editcartId];
    if (
      cartItem.cart_item_id !== "" &&
      typeof cartItem.cart_item_id !== undefined &&
      typeof cartItem.cart_item_id !== "undefined"
    ) {
      var postObject = {};
      postObject = {
        app_id: appId,
        cart_item_id: cartItem.cart_item_id,
        cartAction: "Delete",
      };
      var cartPro = this.state.productData[0];
      this.setState({
        selectedCategory: cartPro.cat_slug,
        selectedSubCategory: cartPro.product_slug,
      });
      if (typeof cookie.load("UserId") === "undefined") {
        postObject["reference_id"] = getReferenceID();
      } else {
        postObject["customer_id"] = cookie.load("UserId");
      }
      axios
        .post(apiUrlCtrng + "cart/delete", qs.stringify(postObject))
        .then((res) => {
          if (res.data.status === "ok") {
            this.addToCartCombo();
          }
        });
    }
  }

  scrollPreticularDiv(class_Name) {
    if ($(".error_scroll").length > 0) {
      if (
        class_Name === "" ||
        typeof class_Name === undefined ||
        typeof class_Name === "undefined"
      ) {
        var class_Name = $(".error_scroll:visible:first");
      }
      if (class_Name.length > 0) {
        $("html, body").animate(
          {
            scrollTop: class_Name.offset().top - 150,
          },
          500
        );
      }
    }
  }

  productListMenu() {
    var productData = this.state.productData;
    var productDetail, staticblockData;

    if (Object.keys(productData.length > 0)) {
      productDetail = productData[this.state.selectedPro];
    }
    var i = 0;
    const product_menu = productData.map((item, index) => {
      if (index <= 2) {
        return (
          <div
            key={index + 1}
            className={
              this.state.selectedProduct === item.product_slug
                ? "menu-list-intvl active"
                : "menu-list-intvl"
            }
          >
            <a
              className="menu-title-link"
              title={
                item.product_alias !== ""
                  ? stripslashes(item.product_alias)
                  : stripslashes(item.product_name)
              }
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.changeProduct(index, item, "");
              }}
            >
              {item.product_alias !== ""
                ? stripslashes(item.product_alias)
                : stripslashes(item.product_name)}
            </a>
          </div>
        );
      }
    });

    return product_menu;
  }

  productListHidden() {
    var productData = this.state.productData;
    var productDetail, staticblockData;

    if (Object.keys(productData.length > 0)) {
      productDetail = productData[this.state.selectedPro];
    }
    var i = 0;
    const product_menu = productData.map((item, index) => {
      if (index > 2) {
        i++;
        $(".more-menu-parent, .more_categor_info").addClass("active");
        return (
          <div
            key={index + 1}
            className={
              this.state.selectedProduct === item.product_slug
                ? "menu-list-intvl active"
                : "menu-list-intvl"
            }
          >
            <a
              className="menu-title-link"
              title={
                item.product_alias !== ""
                  ? stripslashes(item.product_alias)
                  : stripslashes(item.product_name)
              }
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.changeProduct(index, item, item.product_name);
              }}
            >
              {item.product_alias !== ""
                ? stripslashes(item.product_alias)
                : stripslashes(item.product_name)}
            </a>
          </div>
        );
      }
    });

    if (i > 0) {
      $(".more-menu").show();
    } else {
      $(".more-menu").hide();
    }

    return product_menu;
  }

  render() {
    var productData = this.state.productData;
    var productDetail, staticblockData;

    if (Object.keys(productData.length > 0)) {
      productDetail = productData[this.state.selectedPro];
    }
    if (this.props.staticblockData && this.props.staticblockData.length > 0) {
      staticblockData = this.props.staticblockData[0].result_set;
    }

    return (
      <>
        {this.state.product_loading === false &&
        this.state.cartEditStart === 1 ? (
          productData.length > 0 && (
            <>
              <div className="mobile-menu-section-left">
                <Slider {...settingsPorduct}>
                  {productData.map((item, index) => {
                    return (
                      <div
                        className={
                          this.state.selectedProduct === item.product_slug
                            ? "menu-list-intvl active"
                            : "menu-list-intvl"
                        }
                        key={index}
                      >
                        <a
                          className="menu-title-link"
                          title={
                            item.product_alias !== ""
                              ? stripslashes(item.product_alias)
                              : stripslashes(item.product_name)
                          }
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.changeProduct(index, item, "");
                          }}
                        >
                          {item.product_alias !== ""
                            ? stripslashes(item.product_alias)
                            : stripslashes(item.product_name)}
                        </a>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              <div className="menu-section-catering menu-section-left">
                <div className="menu-section-left-inner">
                  {this.productListMenu()}
                </div>
                <div
                  className="more-menu open_more_categories"
                  style={{ display: "none" }}
                >
                  <div className="more-menu-parent">
                    <span className="more-menu-name">CHECK MORE</span>
                    <label className="open_more_category">
                      <i className="fa fa-angle-double-down"></i>
                    </label>
                  </div>
                  <ul style={{ display: "none" }} className="more_categor_info">
                    {this.productListHidden()}
                  </ul>
                </div>
              </div>
              {productDetail !== "" && productDetail !== undefined && (
                <div className="products_list">
                  <div className="product_details_popup">
                    <div className="main_product_index">
                      <div className="package_details">
                        <div className="package_details_left">
                          <Slider {...settings}>
                            {productDetail.image_gallery.length > 0 &&
                            productDetail.image_gallery !== undefined ? (
                              (productDetail.image_gallery || []).map(
                                (image, index) => {
                                  return (
                                    <div key={index}>
                                      <img
                                        src={
                                          this.state.prCommon
                                            .product_gallery_image_source +
                                          "/" +
                                          image.pro_gallery_image
                                        }
                                        alt="product"
                                      />
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <img src={productNoImg} alt="" />
                            )}
                          </Slider>
                        </div>
                        <div className="package_details_right">
                          <div className="package_details_right_inner">
                            <div className="pdr_left">
                              <h4>
                                {productDetail.product_alias !== ""
                                  ? stripslashes(productDetail.product_alias)
                                  : stripslashes(productDetail.product_name)}
                              </h4>
                              <p>
                                {productDetail.product_long_description != "" &&
                                productDetail.product_long_description != null
                                  ? Parser(
                                      stripslashes(
                                        productDetail.product_long_description
                                      )
                                    )
                                  : productDetail.product_short_description !=
                                      "" &&
                                    productDetail.product_short_description !=
                                      null
                                  ? Parser(
                                      stripslashes(
                                        productDetail.product_short_description
                                      )
                                    )
                                  : ""}
                              </p>
                              {productDetail.product_pdf !== "" &&
                                productDetail.product_pdf !== null && (
                                  <div className="package_pdfico">
                                    <a
                                      href={
                                        this.state.prCommon.pdf_source +
                                        "/" +
                                        productDetail.product_pdf
                                      }
                                      target="_blank"
                                    >
                                      <i className="fa fa-file-pdf-o"></i>
                                      <span>Download File</span>
                                    </a>
                                  </div>
                                )}
                            </div>
                            <div className="pdr_right">
                              <div className="fix-only-lhs">
                                <h3>
                                  {productDetail.product_alias !== ""
                                    ? stripslashes(productDetail.product_alias)
                                    : stripslashes(productDetail.product_name)}
                                </h3>
                              </div>
                              <div className="pdr_right-inner">
                                <h4>
                                  {parseInt(this.state.productGrandTotal) > 0
                                    ? Parser(
                                        showPriceValue(
                                          this.state.productGrandTotal
                                        )
                                      )
                                    : Parser(
                                        showPriceValue(
                                          productDetail.product_min_pax *
                                            productDetail.product_price
                                        )
                                      )}
                                </h4>
                                {productDetail.hide_min_pax_label != 1 && (
                                  <h5>Choose Pax</h5>
                                )}
                                <span className="counter qty_bx">
                                  <span
                                    className="qty_minus"
                                    onClick={this.updateProductPax.bind(
                                      this,
                                      "minus",
                                      this.state.selectpaxcount <
                                        productDetail.product_min_pax
                                        ? productDetail.product_min_pax
                                        : this.state.selectpaxcount,
                                      productDetail.product_min_pax,
                                      productDetail.product_max_pax,
                                      productDetail.increase_quantity
                                    )}
                                  >
                                    -
                                  </span>
                                  <strong className="products_quantity proqty_input">
                                    {this.state.selectpaxcount}
                                  </strong>

                                  <span
                                    className="qty_plus"
                                    onClick={this.updateProductPax.bind(
                                      this,
                                      "plus",
                                      this.state.selectpaxcount <
                                        productDetail.product_min_pax
                                        ? productDetail.product_min_pax
                                        : this.state.selectpaxcount,
                                      productDetail.product_min_pax,
                                      productDetail.product_max_pax,
                                      productDetail.increase_quantity
                                    )}
                                  >
                                    +
                                  </span>
                                </span>
                                {productDetail.hide_min_pax_label !== "1" && (
                                  <i className="product-min-pax">
                                    MIN Pax : {productDetail.product_min_pax}
                                  </i>
                                )}

                                {/* Object.keys(productDetail.self_collection)
                                  .length > 0 &&
                                  Object.keys(productDetail.delivery).length >
                                    0 &&
                                  this.state.selectpaxcount >= 50 && (
                                    <i className="product-min-pax">
                                      Delivery <br /> Self Collection
                                    </i>
                                  ) */}

                                {/* Object.keys(productDetail.self_collection)
                                  .length > 0 &&
                                  this.state.selectpaxcount < 30 && (
                                    <i className="product-min-pax">
                                      Self Collection
                                    </i>
                                  ) */}

                                {typeof productDetail.product_tag !==
                                  "undefined" &&
                                  productDetail.product_tag.length > 0 && (
                                    <div className="flavours">
                                      {(productDetail.product_tag || []).map(
                                        (tag, index) => {
                                          if (tag.pro_tag_image !== "") {
                                            return (
                                              <img
                                                src={
                                                  this.state.prCommon
                                                    .tag_image_source +
                                                  tag.pro_tag_image
                                                }
                                                alt={tag.pro_tag_name}
                                                key={index}
                                              />
                                            );
                                          }
                                        }
                                      )}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="product_acc_grp">
                        {(productDetail.set_menu_component || []).map(
                          (component, cindex) => {
                            return (
                              <div
                                className="accordion combo_set"
                                key={cindex}
                                id={`${cindex}s`}
                              >
                                <div className="accordion_title active">
                                  {" "}
                                  {component.menu_set_component_name}{" "}
                                  {component.menu_component_min_select > 0 && (
                                    <span>
                                      Minimum Select{" "}
                                      {component.menu_component_min_select}{" "}
                                    </span>
                                  )}
                                </div>
                                <div className="accordion_body main_combo_div">
                                  <div className="accordion_body_inner">
                                    <div
                                      className="pkgerror_txt min_product_err error_scroll"
                                      style={{ display: "none" }}
                                    >
                                      Please select 1 more item(s)!
                                    </div>
                                    <div className="product_card_desc">
                                      <p>
                                        {component.menu_set_description != null
                                          ? Parser(
                                              stripslashes(
                                                component.menu_set_description
                                              )
                                            )
                                          : ""}
                                      </p>
                                    </div>
                                    {component.product_details.length > 0 && (
                                      <div className="product_det_cards">
                                        <ul>
                                          {(
                                            component.product_details || []
                                          ).map((product_details, mindex) => {
                                            var checkProSelected =
                                              this.checkProductSelected(
                                                product_details
                                              );

                                            return (
                                              <li
                                                className={
                                                  checkProSelected.selected ===
                                                  true
                                                    ? "det_card compo_minmax_maindiv selected"
                                                    : "det_card compo_minmax_maindiv"
                                                }
                                                key={mindex}
                                                id={"pro-" + mindex}
                                                data-customlogo="0"
                                                data-extraqty={
                                                  checkProSelected.selected ===
                                                  true
                                                    ? checkProSelected.extraqty
                                                    : 0
                                                }
                                                data-extraprice={
                                                  checkProSelected.selected ===
                                                  true
                                                    ? checkProSelected.extraprice
                                                    : 0
                                                }
                                                data-minqty={
                                                  product_details.product_min_pax >
                                                  0
                                                    ? product_details.product_min_pax
                                                    : 0
                                                }
                                                data-qty={
                                                  checkProSelected.qty > 0
                                                    ? checkProSelected.qty
                                                    : "0"
                                                }
                                                data-incquantity={
                                                  product_details.increase_quantity >
                                                  0
                                                    ? product_details.increase_quantity
                                                    : 1
                                                }
                                                data-modifiers={
                                                  product_details.modifiers
                                                    .length > 0
                                                    ? 1
                                                    : 0
                                                }
                                              >
                                                <div
                                                  className={
                                                    "det_inner individual_combo_pro"
                                                  }
                                                >
                                                  <div className="det_inner_image">
                                                    {product_details.product_thumbnail !==
                                                    "" ? (
                                                      <img
                                                        className="no-pro"
                                                        src={
                                                          this.state.prCommon
                                                            .image_source +
                                                          "/" +
                                                          product_details.product_thumbnail
                                                        }
                                                        alt=""
                                                      />
                                                    ) : (
                                                      <img
                                                        src={productNoImg}
                                                        alt=""
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="det_inner_content">
                                                    <h5 className="text_center">
                                                      {product_details.product_alias !==
                                                      ""
                                                        ? stripslashes(
                                                            product_details.product_alias
                                                          )
                                                        : stripslashes(
                                                            product_details.product_name
                                                          )}
                                                    </h5>
                                                    <p className="product_desc text_center">
                                                      {product_details.product_short_description !==
                                                      ""
                                                        ? Parser(
                                                            stripslashes(
                                                              product_details.product_short_description
                                                            )
                                                          )
                                                        : ""}
                                                    </p>
                                                    {product_details.modifiers
                                                      .length > 0 && (
                                                      <>
                                                        {this.modfierValuelistMain(
                                                          product_details.modifiers,
                                                          product_details.product_quantity_inc,
                                                          cindex,
                                                          mindex,
                                                          checkProSelected
                                                        )}

                                                        <div
                                                          className="text_center modifier-btn-grp"
                                                          style={{
                                                            display:
                                                              checkProSelected.selected ===
                                                              true
                                                                ? ""
                                                                : "none",
                                                          }}
                                                        >
                                                          <a
                                                            href="#"
                                                            className="button mdf-done"
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              this.completeModifiers(
                                                                cindex,
                                                                mindex
                                                              );
                                                            }}
                                                          >
                                                            Done
                                                          </a>
                                                          <a
                                                            href="#"
                                                            className="button mdf-cancel"
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              this.cancelModifiers(
                                                                cindex,
                                                                mindex
                                                              );
                                                            }}
                                                          >
                                                            cancel
                                                          </a>
                                                        </div>
                                                      </>
                                                    )}
                                                    {product_details.product_quantity_inc ==
                                                      1 &&
                                                      product_details.modifiers
                                                        .length == 0 &&
                                                      this.qtyoptionContent(
                                                        cindex,
                                                        mindex,
                                                        product_details,
                                                        checkProSelected
                                                      )}
                                                    {parseInt(
                                                      product_details.custom_text_logo
                                                    ) === 1 ? (
                                                      <div
                                                        className="custom_logo_banner"
                                                        style={{
                                                          display:
                                                            checkProSelected.selected ===
                                                              true ||
                                                            checkProSelected.custom_logo !==
                                                              "" ||
                                                            checkProSelected.custom_text
                                                              ? ""
                                                              : "none",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="input_field">
                                                            <textarea
                                                              className="form-control custom_banner_text"
                                                              placeholder="Enter your custom title here..."
                                                              maxLength="15"
                                                              name="custom_banner_text"
                                                              defaultValue={
                                                                checkProSelected.selected ===
                                                                  true &&
                                                                checkProSelected.custom_text !==
                                                                  ""
                                                                  ? checkProSelected.custom_text
                                                                  : ""
                                                              }
                                                            ></textarea>
                                                            <span className="input_desc">
                                                              Maximum 15
                                                              characters
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div className="input-file-sec">
                                                          <input
                                                            type="file"
                                                            name="custom_logo_banner"
                                                            className=""
                                                            id="upload-file"
                                                            onChange={this.handlecustomLogoChange.bind(
                                                              this,
                                                              cindex,
                                                              mindex
                                                            )}
                                                          />
                                                          <span className="file-name">
                                                            Your Logo
                                                          </span>
                                                          <a
                                                            href="#"
                                                            className="btn btn_dark"
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                            }}
                                                          >
                                                            Browse Logo
                                                          </a>
                                                        </div>
                                                        <div className="file_details">
                                                          <span className="imagename">
                                                            {checkProSelected.selected ===
                                                              true &&
                                                            checkProSelected.custom_logo !==
                                                              ""
                                                              ? checkProSelected.custom_logo
                                                              : ""}
                                                          </span>
                                                          <a
                                                            href="#"
                                                            className="delete_image"
                                                            style={{
                                                              display:
                                                                checkProSelected.selected ===
                                                                  true &&
                                                                checkProSelected.custom_logo !==
                                                                  ""
                                                                  ? ""
                                                                  : "none",
                                                            }}
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              this.removecustomLogo(
                                                                cindex,
                                                                mindex
                                                              );
                                                            }}
                                                          >
                                                            <img
                                                              src={close}
                                                              alt="close"
                                                            />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <b
                                                      className="popup_price"
                                                      style={{
                                                        display:
                                                          checkProSelected.selected ==
                                                            true &&
                                                          parseFloat(
                                                            checkProSelected.extraprice
                                                          ) > 0
                                                            ? ""
                                                            : "none",
                                                      }}
                                                    >
                                                      {checkProSelected.selected ==
                                                        true &&
                                                      parseFloat(
                                                        checkProSelected.extraprice
                                                      )
                                                        ? showPriceValueNew(
                                                            checkProSelected.extraprice
                                                          )
                                                        : parseFloat(
                                                            product_details.product_price
                                                          ) > 0
                                                        ? showPriceValueNew(
                                                            product_details.product_price
                                                          ) + " / pax"
                                                        : ""}
                                                    </b>
                                                    <div
                                                      className="text_center det-wrap custom_checkbox select_product"
                                                      style={{
                                                        display:
                                                          checkProSelected.selected ===
                                                          true
                                                            ? "none"
                                                            : "",
                                                      }}
                                                    >
                                                      <a
                                                        href="#"
                                                        onClick={(evt) => {
                                                          evt.preventDefault();
                                                          this.incComboQty(
                                                            cindex,
                                                            mindex,
                                                            "add"
                                                          );
                                                        }}
                                                        className="button show_details"
                                                      >
                                                        Select
                                                      </a>
                                                    </div>
                                                    <a
                                                      href="#"
                                                      onClick={(evt) => {
                                                        evt.preventDefault();
                                                        this.incComboQty(
                                                          cindex,
                                                          mindex,
                                                          "remove"
                                                        );
                                                      }}
                                                      className="prd_close"
                                                      style={{
                                                        display:
                                                          checkProSelected.selected ===
                                                            true &&
                                                          product_details
                                                            .modifiers
                                                            .length === 0
                                                            ? ""
                                                            : "none",
                                                      }}
                                                    >
                                                      <span>Unselect</span>
                                                    </a>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                      {(Object.keys(productDetail.delivery).length > 0 ||
                        Object.keys(productDetail.buffet).length > 0 ||
                        Object.keys(productDetail.self_collection).length >
                          0) && (
                        <div className="additionals additionals_ex">
                          <h5>
                            <span>Setup</span>
                            <small>(Choose your buffet setup)</small>
                          </h5>
                          <div className="pkgerror_txt min_product_err error_scroll SetupError"></div>
                          <ul className="additionals_setuplist">
                            {Object.keys(productDetail.self_collection).length >
                              0 && (
                              <li>
                                <div className="custom_radio">
                                  <input
                                    type="radio"
                                    name="settup_details"
                                    value="self_collection"
                                    defaultChecked={
                                      this.state.settupType ===
                                      "self_collection"
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleUpdateCurrentSetup.bind(
                                      this,
                                      "self_collection"
                                    )}
                                  />{" "}
                                  <span>Self Collection</span>
                                </div>
                                {this.state.settupType ===
                                  "self_collection" && (
                                  <ul className="additionals-deliverylist self_collection_listing_div">
                                    {this.getSelfCollectionsetup(
                                      productDetail.self_collection
                                    )}
                                  </ul>
                                )}
                              </li>
                            )}

                            {Object.keys(productDetail.delivery).length > 0 && (
                              <li>
                                <div className="custom_radio">
                                  <input
                                    type="radio"
                                    name="settup_details"
                                    value="delivery"
                                    checked={
                                      this.state.settupType === "delivery"
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleUpdateCurrentSetup.bind(
                                      this,
                                      "delivery"
                                    )}
                                  />{" "}
                                  <span>Delivery</span>
                                </div>
                                {this.state.settupType === "delivery" && (
                                  <ul className="additionals-deliverylist additionals_deliverylist">
                                    {this.getDeliverysetup(
                                      productDetail.delivery
                                    )}
                                  </ul>
                                )}
                              </li>
                            )}

                            {Object.keys(productDetail.buffet).length > 0 && (
                              <li>
                                <div className="custom_radio">
                                  <input
                                    type="radio"
                                    name="settup_details"
                                    value="buffet"
                                    defaultChecked={
                                      this.state.settupType === "buffet"
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleUpdateCurrentSetup.bind(
                                      this,
                                      "buffet"
                                    )}
                                  />{" "}
                                  <span>Buffet</span>
                                </div>
                                {this.state.settupType === "buffet" && (
                                  <ul className="additionals-deliverylist buffet_listing_div">
                                    {this.getBuffetsetup(productDetail.buffet)}
                                  </ul>
                                )}
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                      {Object.keys(productDetail.equipment).length > 0 && (
                        <div className="additionals additionals_ex">
                          <div className="row">
                            <div className="col-lg-12">
                              <h5>Equipment</h5>
                              <ul className="additionals-deliverylist additionals_equipmentlist equipment_listing_div">
                                {this.getEquipmentsetup(
                                  productDetail.equipment
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                      {Object.keys(productDetail.addons_setup).length > 0 && (
                        <div className="additionals additionals_ex">
                          <h5>
                            <span>Addons</span>
                            <small>(Choose your addons )</small>
                          </h5>
                          <ul className="addon_checklist clearfix">
                            {this.productAddonsSetup(
                              productDetail.addons_setup
                            )}
                          </ul>
                        </div>
                      )}
                      <div className="additionals">
                        {/*(staticblockData || []).map((staticblock, index) => {
                          return (
                            <div key={index}>
                              {staticblock.staticblocks_slug === "disclaimer"
                                ? Parser(
                                    stripslashes(
                                      staticblock.staticblocks_description
                                    )
                                  )
                                : ""}
                            </div>
                          );
                        })*/}

                        {productDetail.product_receipe_info !== "" &&
                        productDetail.product_receipe_info !== undefined &&
                        productDetail.product_receipe_info !== null
                          ? Parser(
                              stripslashes(productDetail.product_receipe_info)
                            )
                          : ""}
                      </div>
                      <div className="instruction">
                        <h5>Add special instruction</h5>
                        <div className="form-group">
                          <div className="input_field">
                            <textarea
                              className="form-control spl_instruction"
                              name="specialInstruction"
                              value={this.state.specialInstruction}
                              onChange={this.handleChangeText.bind(this)}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <Link
                        to="#"
                        className="button btn_upp btn_fill btn_lg add_to_cart"
                        onClick={this.addToCartCombo.bind(this)}
                      >
                        {this.state.cartID !== ""
                          ? "Update Cart"
                          : "Add To Cart"}
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </>
          )
        ) : (
          <div className="no-recrds-found loader-main-cls" id="no-recrds-found">
            <div className="loader-main-cls"></div>
          </div>
        )}
      </>
    );
  }
}
export default Product;
