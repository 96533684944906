/* eslint-disable */
if (location.protocol !== "https:") {
  var locationULR = location.href.replace("www.", "");
  location.href = locationULR.replace("http://", "https://www.");
} else if (location.protocol === "https:") {
  var locationULR = location.href;
  if (locationULR.indexOf("www.") < 0) {
    location.href = "https://www." + locationULR.replace("https://", "");
  }
}

import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import { appId } from "./components/Helpers/Config";

import Home from "./components/Home/Home";
import Products from "./components/Products/Products";
import FavouriteProducts from "./components/Products/FavouriteProducts";
import ProductDetail from "./components/Products/ProductDetail";
import FutureProducts from "./components/Products/FutureProducts";
import PromotionProducts from "./components/Products/PromotionProducts";
import Checkout from "./components/Checkout/Checkout";
import Thankyou from "./components/Checkout/Thankyou";
import Findingdriver from "./components/Checkout/Findingdriver";
import Payment from "./components/Payment/Payment";
import PaymentThankyou from "./components/Payment/Thankyou";
import Pages from "./components/Pages/Pages";
import Faq from "./components/Pages/Faq";
import Aboutus from "./components/Pages/Aboutus";
import ContactUs from "./components/Pages/ContactUs";
import Outlets from "./components/Pages/Outlets";
import Myaccount from "./components/Myaccount/Myaccount";
import Orders from "./components/Myaccount/Orders";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Rewards from "./components/Myaccount/Rewards";
import Myvoucher from "./components/Myaccount/Myvoucher";
import ReferFriends from "./components/Myaccount/ReferFriends";
import Account from "./components/Account/Account";
import Resetpassword from "./components/Account/Resetpassword";
import Scanqrcode from "./components/Dineqrcode/Scanqrcode";
import Booktable from "./components/Dineqrcode/Booktable";
import Myreservation from "./components/Myaccount/Myreservation";
import Referral from "./components/Myaccount/Referral";
import Rfcode from "./components/Layout/Rfcode";
/* import Catering from "./components/Catering/Catering";
import Packagecheckout from "./components/Catering/Packagecheckout"; */

/* import Catering from "./components/Catering/Catering";
import CateringThankyou from "./components/Catering/Thankyou";
import Viewmenu from "./components/Catering/Viewmenu"; */

import Maincategory from "./components/Catering/Maincategory";
import Catering from "./components/Catering/index";
import Editcart from "./components/Catering/Editcart";
import Cateringsuccess from "./components/Catering/Refpage";
import Cateringcheckout from "./components/Cateringcheckout/index";
import CateringThankyou from "./components/CateringThankyou/index";

import Reservation from "./components/Reservation/Reservation";
import Reservationsummary from "./components/Reservation/Reservationsummary";
import Reservationthankyou from "./components/Reservation/Reservationthankyou";
import Reservationsuggestion from "./components/Reservation/Reservationsuggestion";
import Placeorder from "./components/Checkout/Placeorder";
import Fomoplaceorder from "./components/Checkout/Fomoplaceorder";
import Failed from "./components/Checkout/Failed";

import Ewalletplaceorder from "./components/Checkout/Ewalletplaceorder";
import Ewalletfailed from "./components/Checkout/Ewalletfailed";

import Membershipinfographic from "./components/Pages/Membershipinfographic";

import Logout from "./components/Myaccount/Logout";
import Refpage from "./components/Layout/Refpage";
import Page404 from "./Page404";
const store = getStore();

var isSubdomain = function (url) {
  var regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);
  return !!url.match(regex);
};

var isSubdomains = function (url) {
  return url.split(".").length > 2;
};

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          path={"/products/:slugType/:slugValue/:proValue"}
          component={Products}
        />
        <Route path={"/products/:slugType/:slugValue"} component={Products} />
        <Route path="/products" component={Products} />
        <Route
          path={"/menu/:slugType/:slugValue/:proValue/:tableNumber"}
          component={ProductDetail}
        />
        <Route
          path={"/menu/:slugType/:slugValue/:proValue"}
          component={ProductDetail}
        />
        <Route path={"/menu/:slugType/:slugValue"} component={Products} />
        <Route path="/menu" component={Products} />
        <Route path="/favourite" component={FavouriteProducts} />
        <Route path="/future-products" component={FutureProducts} />
        <Route path="/promotion-products" component={PromotionProducts} />
        {/* <Route path="/package/checkout" component={Packagecheckout} /> */}
        {/* <Route path="/package" component={Package} />  */}
        {/* <Route path="/package" component={Catering} /> */}
        {/* <Route
          path="/catering/thankyou/:orderId"
          component={CateringThankyou}
        />
        <Route path="/catering" component={Catering} />
        <Route path="/viewmenu" component={Viewmenu} /> */}

        <Route path="/catering-checkout" component={Cateringcheckout} />
        {/* <Route path="/catering/payment/thankyou" component={PyamentThankyou} /> */}
        <Route
          path="/catering/thankyou/:orderId"
          component={CateringThankyou}
        />
        <Route path="/payment/:orderId/:paymentId" component={Payment} />
        <Route
          path="/catering/payment/thankyou/:orderId"
          component={PaymentThankyou}
        />
        <Route path="/catering/edit/:productId/:cartID" component={Catering} />
        <Route
          path="/catering-success/:catSlug/:subCatSlug"
          component={Cateringsuccess}
        />
        <Route path="/catering/:catSlug/:subCatSlug" component={Catering} />
        <Route path="/catering" component={Maincategory} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/ewalletplaceorder" component={Ewalletplaceorder} />
        <Route path="/ewalletfailed" component={Ewalletfailed} />
        <Route path="/ewalletcancelled" component={Ewalletfailed} />
        <Route path="/scanqrcode" component={Scanqrcode} />
        <Route path="/quickbook/:tablenumber" component={Booktable} />
        <Route path="/placeorder" component={Placeorder} />
        <Route path="/fomoplaceorder" component={Fomoplaceorder} />
        <Route path="/failed" component={Failed} />
        <Route path="/thankyou/:orderId" component={Thankyou} />
        <Route path="/findingdriver/:orderId" component={Findingdriver} />
        <Route path="/payment/thankyou" component={PaymentThankyou} />
        <Route path="/payment/:orderId/:paymentId" component={Payment} />
        <Route path="/membership" component={Membershipinfographic} />
        <Route path="/our-story" component={Pages} />
        <Route path="/terms-and-conditions" component={Pages} />
        <Route path="/privacy-policy" component={Pages} />
        <Route path="/promotions" component={Pages} />
        <Route path="/seasonal-promotions" component={Pages} />
        <Route path="/blog" component={Pages} />
        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/faq" component={Faq} />
        <Route path="/about-us" component={Aboutus} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/reservation" component={Reservation} />
        <Route path="/reservation-summary" component={Reservationsummary} />
        <Route path="/reservation-thankyou" component={Reservationthankyou} />
        <Route path="/locations" component={Outlets} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/myorders/:tab" component={Orders} />
        <Route path="/myorders" component={Orders} />
        <Route path="/mycateringorders" component={Orders} />
        <Route path="/myreservations" component={Myreservation} />
        <Route path="/myvouchers" component={Myvoucher} />
        <Route path="/referfriends" component={ReferFriends} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/referral" component={Referral} />
        <Route path={"/rfcode/:slugtext"} component={Rfcode} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/reservation_suggest/:acceptreject/:resid"
          component={Reservationsuggestion}
        />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route path="/logout" component={Logout} />
        <Route
          path={"/refpage/:slugtext/:slugtext1/:slugtext2/:slugtext3"}
          component={Refpage}
        />
        <Route
          path={"/refpage/:slugtext/:slugtext1/:slugtext2/"}
          component={Refpage}
        />
        <Route path={"/refpage/:slugtext/:slugtext1/"} component={Refpage} />
        <Route path={"/refpage/:slugtext/"} component={Refpage} />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route path={"/chopchoptracking/token/:tokenID"} component={Home} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
