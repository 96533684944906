/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { validated } from "react-custom-validation";
import validator from "validator";
import axios from "axios";

import cookie from "react-cookies";
import { appId, apiUrl, baseUrl, mapcountry } from "../Helpers/Config";
import { includes } from "lodash";

const isEmpty = (value) => (value === "" ? "This field is required." : null);

const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";

const phonenumberPattern = /^[0-9]{6,14}$/;
const phonenumberPatternph = /^[0-9]{11,11}$/;

const isMobile = (mobile) =>
  mapcountry == "sg"
    ? mobile.match(phonenumberPattern)
      ? null
      : "Please enter valid Mobile number."
    : mobile.match(phonenumberPatternph)
    ? null
    : "Please enter valid Mobile number.";

var currentAppId = [
  "93EDF976-7242-4864-B57E-7B49306521AC",
  "E66D5AAF-9077-4885-B379-02D3FCB8BB16",
];

function validationConfigCheckout(props) {
  const {
    check_firstname,
    check_lastname,
    check_email,
    check_phone,
    check_ref_code,
  } = props.fields;
  return {
    fields: ["firstname", "email", "mobile", "check_ref_code"],

    validations: {
      firstname: [[isEmpty, check_firstname]],
      email: [[isEmail, check_email]],
      mobile: [
        [isEmpty, check_phone],
        [isMobile, check_phone],
      ],
    },
  };
}

class Guestcheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "Loading",
      check_phone: "",
    };
  }

  componentDidMount() {
    var check_phone = this.props.fields.check_phone;
    this.setState({ check_phone: check_phone });

    var referCodeTxt =
      cookie.load("referCode") == "" || cookie.load("referCode") == undefined
        ? ""
        : cookie.load("referCode");
    this.props.onChange("check_ref_code", referCodeTxt);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ check_phone: nextProps.fields.check_phone });
  }

  isGuestPhonenumerExist() {
    var mobile = $("#check_phone").val();
    this.setState({ check_phone: mobile });
    this.props.onChange("check_phone", mobile);
    if (mobile.length > 7) {
      axios
        .get(
          apiUrl +
            "guestaccount/guest_customer_phone?app_id=" +
            appId +
            "&status=A&phone_no=" +
            mobile
        )
        .then((response) => {
          if (response.data.status === "ok") {
            //this.props.onChange("check_phone", mobile);
            this.props.onChange(
              "check_firstname",
              response.data.result_set.customer_first_name
            );
            this.props.onChange(
              "check_email",
              response.data.result_set.customer_email
            );
          } else {
            //this.props.onChange("check_phone", mobile);
            /*this.props.onChange('check_firstname', '');
                    this.props.onChange('check_lastname', '');
                    this.props.onChange('check_email', ''); */
          }
        });
    } else {
      this.setState({ check_phone: mobile });
    }
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;

    let errMsgFirstName, errMsgLastName, errMsgEmail, errMsgMobile;

    if ($validation.firstname.error.reason !== undefined) {
      errMsgFirstName = $validation.firstname.show && (
        <span className="error">{$validation.firstname.error.reason}</span>
      );
    }
    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }
    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }

    return (
      <div className="popup-body">
        <span className="guest-chk-error"></span>
        <div className="form-group">
          {includes(currentAppId, appId) === true && (
            <div className="focus-out focused">
              <label>Phone number</label>
              <input
                type="tel"
                maxLength={mapcountry === "sg" ? "8" : ""}
                id="check_phone"
                className="form-control input-focus"
                pattern="\d*"
                value={this.state.check_phone}
                placeholder="09XXXXXXXXX"
                onChange={() => this.isGuestPhonenumerExist()}
              />
              <div id="spn-mobile-error">{errMsgMobile}</div>
            </div>
          )}
          {includes(currentAppId, appId) === false && (
            <div className="focus-out">
              <label>Phone number</label>
              <input
                type="tel"
                maxLength={mapcountry === "sg" ? "8" : ""}
                id="check_phone"
                className="form-control input-focus"
                pattern="\d*"
                value={this.state.check_phone}
                onChange={() => this.isGuestPhonenumerExist()}
              />
              <div id="spn-mobile-error">{errMsgMobile}</div>
            </div>
          )}
        </div>
        <div className="form-group">
          <div
            className={
              fields.check_firstname != "" ? "focus-out focused" : "focus-out"
            }
          >
            <label>Name</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.check_firstname}
              {...$field("check_firstname", (e) =>
                onChange("check_firstname", e.target.value)
              )}
            />
            {errMsgFirstName}
          </div>
        </div>

        <div className="form-group">
          <div
            className={
              fields.check_email != "" ? "focus-out focused" : "focus-out"
            }
          >
            <label>Email</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.check_email}
              {...$field("check_email", (e) =>
                onChange("check_email", e.target.value)
              )}
            />
            <div id="spn-email-error">{errMsgEmail}</div>
          </div>
        </div>

        <div className="form-group check_refcode_cls">
          <div
            className={
              fields.check_ref_code != "" ? "focus-out focused" : "focus-out"
            }
          >
            <label>Referral code ( Optional )</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.check_ref_code}
              {...$field("check_ref_code", (e) =>
                onChange("check_ref_code", e.target.value)
              )}
            />
          </div>
        </div>

        <span className="pdpa_trmcont_link">
          By continuing you agree to the{" "}
          <Link
            className="sign_reg_uline disbl_href_action"
            to="/terms-and-conditions"
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
              window.open(baseUrl + "terms-and-conditions", "_newtab");
              // window.location.href = baseUrl+"terms-conditions";
            }}
          >
            PDPA Terms and conditions
          </Link>
          .
        </span>

        <div className="form-group">
          <div className="login_pop_sub login_in_guest">
            <button
              type="button"
              className="btn btn_black btn_minwid guestcheckout_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              Submit
            </button>
          </div>
        </div>

        {/* <div className="form-group">
          <div className="controls single-link">
            <a href="#login-popup" className="open-popup-link">
              Back to login
            </a>
          </div>
        </div> */}
      </div>
    );
  }
}
Guestcheckout = validated(validationConfigCheckout)(Guestcheckout);

export default Guestcheckout;
